<script setup lang="ts">
import { RegistrationStepKeys } from '@/app/helpers/registration/flow';
import { ref } from 'vue';
import OnboardingReview from './OnboardingReview.vue';
import SignatoryTermsReview from './forms/SignatoryTermsReview.vue';
import { Client } from 'ah-api-gateways';

defineProps<{ client: Client }>();

const step = ref<'review' | 'terms'>('review');

const emit = defineEmits<{ (e: 'flow-finished'): void; (e: 'update:client', value: Client): void }>();

function finish() {
  emit('flow-finished');
}
</script>

<template>
  <div>
    <Transition name="fade" appear>
      <div v-if="step === 'review'">
        <h2>Application Approval</h2>

        <OnboardingReview
          :allowEdit="[
            RegistrationStepKeys.COMPANY_DETAILS,
            RegistrationStepKeys.TRADING_DETAILS,
            RegistrationStepKeys.DOCUMENTATION,
            RegistrationStepKeys.ADDITIONAL_USERS,
          ]"
          :client="client"
          success-btn-label="Continue"
          @application-accepted="step = 'terms'"
          @update:client="emit('update:client', $event)"
        />
      </div>
      <SignatoryTermsReview withOBOPermissions :client="client" @approved="finish" v-else />
    </Transition>
  </div>
</template>
