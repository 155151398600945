import defaults from 'lodash/defaults';
import {
  Form,
  Field,
  isForm,
  FormAction,
  FormModel,
  FieldModel,
  RepeatModel,
  BaseFormModel,
  FormActionModel,
  isFieldModel,
} from '../interfaces/form';
import { formPluginOptions } from '../FormsPlugin';

function makeFieldValidation(form: BaseFormModel): { [key: string]: any } {
  const out: { [key: string]: any } = {
    ...form.$validators,
  };

  return out;
}

export function getDefaultValue(field: Field | BaseFormModel) {
  const { defaultValue } = field.state || field.$state || {};
  const type = field.fieldType || field.$type;

  if (defaultValue !== undefined) {
    return defaultValue;
  } else if (type === 'number') {
    return 0;
  } else if (type === 'choiceTree') {
    return [];
  } else if (type === 'form') {
    return {};
  } else if (type === 'checkbox') {
    return false;
  } else {
    return '';
  }
}

export function getDefaultState(fieldType: string) {
  if (fieldType === 'form') {
    return {
      ...formPluginOptions.defaultState.fieldCommon,
      ...formPluginOptions.defaultState.form,
    };
  }
  const fieldDefaults = formPluginOptions.defaultState.fields ? formPluginOptions.defaultState.fields[fieldType] : {};
  return {
    ...formPluginOptions.defaultState.fieldCommon,
    ...fieldDefaults,
  };
}

function makeBaseFormModel(field: Field | Form, pathPrefix = '', parent?: BaseFormModel): BaseFormModel {
  const { state, validators, ...meta } = field;

  return {
    $type: field.fieldType,
    $state: { ...getDefaultState(field.fieldType), ...meta, ...state },
    $path: pathPrefix + field.name,
    $name: field.name,
    $validators: validators || {},
    $value: (state || {}).defaultValue,
    $parent: () => parent,
  };
}

function makeFieldModel(field: Field, pathPrefix = '', parent?: BaseFormModel): FieldModel {
  const model = makeBaseFormModel(field, pathPrefix, parent) as FieldModel;
  model.$value = getDefaultValue(field);

  return model;
}

function makeFormActionModel(formAction: FormAction): FormActionModel {
  return defaults({}, formAction, {
    class: [],
    enabled: true,
    allowOnInvalid: true,
    loading: false,
  });
}

function makeMultipleFormModel(form: Form, pathPrefix = '', parent?: BaseFormModel): RepeatModel {
  const model = makeBaseFormModel(form, pathPrefix, parent) as RepeatModel;

  model.$actions = (form.actions || []).map(makeFormActionModel);

  model.$childForm = {
    ...form,
    state: form.childrenState || {},
    actions: form.childrenActions || [],
    multiple: false,
  };

  model.$constructor = () => makeFormModel(model.$childForm, pathPrefix);
  model.$value = [...Array(Math.max(form.length || 0, 0))].map(model.$constructor);

  return model;
}

export function makeValidation(model: BaseFormModel): { [key: string]: any } {
  return isFieldModel(model) ? makeFieldValidation(model) : makeFormValidation(model as FormModel);
}

export function makeFormValidation(form: FormModel | RepeatModel): { [key: string]: any } {
  const out = makeFieldValidation(form);

  let rules = out;
  let baseForm: FormModel = form as any;

  if (form.$state.multiple) {
    out.$each = {};
    rules = out.$each;
    baseForm = form.$constructor();
  }

  baseForm.$fields.forEach((f) => {
    rules[f.$name] = makeValidation(f);
  });

  return out;
}

export function makeModel(
  field: Form | Field,
  pathPrefix = '',
  parent?: BaseFormModel
): FormModel | FieldModel | RepeatModel {
  if (isForm(field)) {
    if (field.multiple) {
      return makeMultipleFormModel(field, pathPrefix, parent);
    }
    return makeFormModel(field, pathPrefix, parent);
  }
  return makeFieldModel(field, pathPrefix, parent);
}

export function makeFormModel(form: Form, pathPrefix = '', parent?: BaseFormModel): FormModel {
  const model = makeBaseFormModel(form, pathPrefix, parent) as FormModel;

  model.$actions = (form.actions || []).map(makeFormActionModel);
  model.$fields = [];

  form.fields.forEach((f) => {
    const field = makeModel(f, `${model.$path}.`, model);
    model.$fields.push(field);
    if (field.$type === 'form' && !field.$value) {
      model[f.name] = field;
    } else {
      model[f.name] = field.$value;
    }
  });

  return model;
}
