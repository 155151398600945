<script setup lang="ts">
import { makeValidation } from '../../../form/helpers';
import { BaseFormModel, FormValidation } from '../../../form/interfaces';
import useVuelidate from '@vuelidate/core';
import DynamicForm from '../DynamicForm.vue';
import { PropType, ref, computed, watch } from 'vue';

const props = defineProps({
  fm: {
    type: Object as PropType<BaseFormModel>,
    required: true,
  },
});

const emit = defineEmits({
  'update:validation': (_value: FormValidation) => true,
});

const form = ref<InstanceType<typeof DynamicForm>>();

const rules = computed(() => ({
  fm: makeValidation(props.fm),
}));

const validation = useVuelidate<{ fm: BaseFormModel }>(
  rules,
  computed(() => ({
    fm: props.fm,
  }))
);

watch(
  () => validation.value.fm,
  () => emit('update:validation', validation.value.fm),
  { immediate: true }
);

defineExpose({
  validate() {
    return validation.value.$validate();
  },
  form,
});
</script>

<template>
  <DynamicForm ref="form" :form="validation.fm" v-if="validation.fm" v-on="$listeners">
    <template v-for="slot in Object.keys($scopedSlots)" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </DynamicForm>
</template>
