<script setup lang="ts">
import { computed, PropType, ref, watch } from 'vue';
import InfoBlock, { InformationBlock } from 'ah-common-lib/src/common/components/InfoBlock.vue';
import { CompoundValidation, FormEvent, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { setApiErrorMessages, setState, toDataModel } from 'ah-common-lib/src/form/helpers';
import {
  checkAddressHistoryValidity,
  EntityAddressHistory,
  IndividualType,
  OnboardingIndividualInfo,
  UserCreationRequest,
} from 'ah-api-gateways';
import ProposedUserPermissionsForm from '@/app/components/registration/forms/company/ProposedUserPermissionsForm.vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import {
  AdditionalUserFormObj,
  useRegistrationIndividuals,
} from '../../registration/forms/company/useRegistrationIndividuals';
import { useToast } from 'ah-common-lib/src/toast';
import { useTheme } from 'ah-theme';
import { helpers } from '@vuelidate/validators';
import { makeMemberForm } from './reviewForms';
import RemoveButton from '../../registration/forms/common/RemoveButton.vue';
import ClientMemberInfoBlock from './ClientMemberInfoBlock.vue';
import RegistrationAddressForm from '../../registration/forms/common/RegistrationAddressForm.vue';
import ReviewScreenPreviousAddressesForms from './ReviewScreenPreviousAddressesForms.vue';
import { constructPayloadErrors } from 'ah-requests/helpers/apiErrors';

const theme = useTheme();

const props = defineProps({
  editable: {
    type: Boolean,
    default: true,
  },
  model: {
    type: Array as PropType<OnboardingIndividualInfo[]>,
    required: true,
  },
});

const emit = defineEmits<{
  (e: 'update:model', value: OnboardingIndividualInfo[]): void;
}>();
const requestManager = useRequestManager().manager;

const individuals = computed(() => props.model);

const toast = useToast();

const primaryOwnerAddressValidation = ref<FormValidation>();
const primaryOwnerPreviousAddressesValidation = ref<CompoundValidation>();

const secondaryOwnerAddressValidation = ref<FormValidation>();
const secondaryOwnerPreviousAddressesValidation = ref<CompoundValidation>();

const differentFromIndividuals = helpers.withParams(
  { type: 'differentFromIndividuals' },
  (val: any) => !registrationIndividuals.isDuplicatedEmail(val)
);

const differentFromApplicant = helpers.withParams(
  { type: 'differentFromApplicant' },
  (val: any) => registrationIndividuals.applicantEmail.value !== val
);

const differentFromOwner = helpers.withParams(
  { type: 'differentFromOwner' },
  (val: any) => registrationIndividuals.ownerEmail.value !== val
);

const differentFromSecondaryOwner = helpers.withParams(
  { type: 'differentFromSecondaryOwner' },
  (val: any) => registrationIndividuals.secondaryOwnerEmail.value !== val
);

function ownerEmailValidations() {
  return { differentFromApplicant, differentFromIndividuals, differentFromSecondaryOwner };
}

function secondaryOwnerEmailValidations() {
  return { differentFromApplicant, differentFromIndividuals, differentFromOwner };
}

function otherEmailValidations() {
  return { differentFromApplicant, differentFromIndividuals, differentFromSecondaryOwner, differentFromOwner };
}

const registrationIndividuals = useRegistrationIndividuals({
  requestManager,
  individuals,
  userFormFactory: (individual) => {
    if (individual?.owner) {
      return makeMemberForm(true, ownerEmailValidations(), true);
    } else if (individual?.secondaryOwner) {
      return makeMemberForm(true, secondaryOwnerEmailValidations(), false);
    }
    return makeMemberForm(false, otherEmailValidations(), false);
  },
});

const editing = ref(false);
const showErrorMessage = ref(false);

const partnerName = computed(() => theme?.val?.name || '');

const applicantIsLabel = computed(() =>
  registrationIndividuals.isApplicantOwner.value ? 'Authorised Signatory' : 'Employee'
);

const isPrimaryOwnerUKResident = computed(
  () => registrationIndividuals.owner.value?.individual?.currentAddress?.countryCode === 'GB'
);

const isSecondaryOwnerUKResident = computed(
  () => registrationIndividuals.secondaryOwner.value?.individual?.currentAddress?.countryCode === 'GB'
);

const primaryOwnerAddressApiErrors = computed(() => {
  const apiErrors = registrationIndividuals.owner.value?.individual?.addressHistoryApiError;
  return apiErrors ? constructPayloadErrors<EntityAddressHistory>(apiErrors) : undefined;
});

const secondaryOwnerAddressApiErrors = computed(() => {
  const apiErrors = registrationIndividuals.secondaryOwner.value?.individual.addressHistoryApiError;
  return apiErrors ? constructPayloadErrors<EntityAddressHistory>(apiErrors) : undefined;
});

const representativeBlock = ref<InformationBlock[]>([
  {
    label: `${registrationIndividuals.applicant.value?.individual.firstName ?? 'Applicant'} is...`,
    key: 'is',
    value: applicantIsLabel.value,
    cols: 4,
  },
  {
    label: 'Requested Permissions',
    key: 'permissions',
    value:
      registrationIndividuals.applicant.value?.individual.type === IndividualType.CLIENT_ADMIN ? 'Superuser' : 'Custom',
    cols: 4,
  },
]);

const userBlock = ref<InformationBlock[]>([
  { label: 'Title', key: 'title', cols: 12 },
  { label: 'First Name', key: 'firstName', cols: 4 },
  { label: 'Last Name', key: 'lastName', cols: 4 },
  { label: 'Email Address', key: 'email', cols: 4 },
  { label: 'Mobile Number', key: 'phoneNumber', cols: 4 },
  { label: 'Date of Birth', key: 'birthDate', cols: 4 },
  { label: 'Requested Permissions', key: 'permissions', cols: 4 },
]);

const isEditable = computed(() => props.editable !== false);

const areFormsInvalid = computed(() => {
  const isPrimaryOwnerInvalid =
    !!primaryOwnerAddressValidation.value?.$invalid || !!primaryOwnerPreviousAddressesValidation.value?.$invalid;

  let isSecondaryOwnerInvalid = false;
  if (registrationIndividuals.secondaryOwner.value) {
    isSecondaryOwnerInvalid =
      !!secondaryOwnerAddressValidation.value?.$invalid || !!secondaryOwnerPreviousAddressesValidation.value?.$invalid;
  }

  const isRegistrationIndividualsInvalid = !!registrationIndividuals.validation.value?.$invalid;

  return isPrimaryOwnerInvalid || isSecondaryOwnerInvalid || isRegistrationIndividualsInvalid;
});

function checkOwnerAddressValidity(individual?: OnboardingIndividualInfo) {
  const isUKResident = individual?.currentAddress?.countryCode === 'GB';
  if (!isUKResident || !individual) {
    return true;
  }

  const addressHistoryValidity = checkAddressHistoryValidity({
    currentAddress: { ...individual?.currentAddress },
    previousAddresses: individual?.previousAddresses || [],
  });
  return addressHistoryValidity.age && addressHistoryValidity.gaps;
}

function save() {
  registrationIndividuals.submitForms(registrationIndividuals.additionalUsers.value);
  if (areFormsInvalid.value) {
    toast.error('Form contains errors! Please review and resubmit.');
    return;
  }

  if (
    !checkOwnerAddressValidity(registrationIndividuals.owner.value?.individual) ||
    !checkOwnerAddressValidity(registrationIndividuals.secondaryOwner.value?.individual)
  ) {
    showErrorMessage.value = true;
    toast.error('Please review the address history. Ensure it spans at least 3 years with no gaps for both owners.');
    return;
  }

  registrationIndividuals.saveEntries().subscribe(
    () => {
      editing.value = false;
      showErrorMessage.value = false;
      emit(
        'update:model',
        registrationIndividuals.individualEntries.value.map((i) => i.individual)
      );
      toast.success('Changes updated successfully.');
    },
    () => {
      toast.error('Please review addresses. UK residents must provide addresses spanning back 3 years, with no gaps.');
      showErrorMessage.value = true;
    }
  );
}

function hasAnyPermissionsSet(individual: OnboardingIndividualInfo) {
  return (individual.proposedPermissions?.findIndex((p) => p.allow) ?? -1) >= 0;
}

function onUserFormEvent(event: FormEvent, userForm: AdditionalUserFormObj) {
  if (event.event === 'form-field-set-value') {
    setState(userForm.form, 'errors', [], true);

    registrationIndividuals.updateEntry(
      {
        ...userForm.individual,
        ...toDataModel(userForm.form),
      },
      userForm
    );
  }
}

function toggleEditing() {
  editing.value = !editing.value;

  if (!editing.value) {
    registrationIndividuals.resetEntries();
  }
}

watch(
  () => registrationIndividuals.owner.value?.individual.userCreationApiError,
  (newVal, oldVal) => {
    const form = registrationIndividuals.owner.value?.form;
    if (form && newVal?.occurrenceId && newVal?.occurrenceId !== oldVal?.occurrenceId) {
      setState(form, 'errors', [], true);
      setApiErrorMessages(constructPayloadErrors<UserCreationRequest>(newVal), form);
    }
  }
);

watch(
  () => registrationIndividuals.secondaryOwner.value?.individual.userCreationApiError,
  (newVal, oldVal) => {
    const form = registrationIndividuals.secondaryOwner.value?.form;
    if (form && newVal?.occurrenceId && newVal?.occurrenceId !== oldVal?.occurrenceId) {
      setState(form, 'errors', [], true);
      setApiErrorMessages(constructPayloadErrors<UserCreationRequest>(newVal), form);
    }
  }
);

watch(
  () =>
    registrationIndividuals.additionalUsers.value
      .filter((additionalUser) => additionalUser.individual.userCreationApiError)
      .map((user) => ({
        form: user.form,
        apiError: user.individual.userCreationApiError,
      })),
  (newVal, oldVal) => {
    // Clear any forms with API errors which existed (by ID) in the previous iteration and DO NOT exist in the current one
    oldVal
      ?.filter((prevUser) => !newVal.find((user) => user.apiError?.occurrenceId === prevUser.apiError?.occurrenceId))
      .forEach((additionalUser) => {
        setState(additionalUser.form, 'errors', [], true);
      });
    // Set errors in any forms wirh API errors which exist (by ID) in the current iteration and DID NOT exist in the previous one
    newVal
      ?.filter((user) => !oldVal?.find((prevUser) => user.apiError?.occurrenceId === prevUser.apiError?.occurrenceId))
      .forEach((additionalUser) => {
        const payloadErrorData = constructPayloadErrors<UserCreationRequest>(additionalUser.apiError!);
        setApiErrorMessages(payloadErrorData, additionalUser.form);
      });
  }
);
</script>

<template>
  <div class="card-block" x-test-name="client-members-review">
    <div class="card-review-header">
      <h2>Authorised Signatory & Users</h2>
      <div class="button-holder" v-if="isEditable">
        <VButton blurOnClick @click="toggleEditing" class="btn-stroked">
          {{ editing ? 'Cancel' : 'Edit' }}
        </VButton>
        <VButton blurOnClick @click="save" v-if="editing" :loading="requestManager.anyPending" class="ml-3">
          Save
        </VButton>
      </div>
    </div>
    <InfoBlock emptyValue="-" :model="individuals" :info="representativeBlock" />
    <template v-if="!editing">
      <template v-if="registrationIndividuals.owner.value">
        <h3 class="my-3">Authorised Signatory</h3>
        <ClientMemberInfoBlock :member="registrationIndividuals.owner.value.individual" />
      </template>

      <template v-if="registrationIndividuals.secondaryOwner.value">
        <h3 class="my-3">Secondary Authorised Signatory</h3>
        <ClientMemberInfoBlock :member="registrationIndividuals.secondaryOwner.value.individual" />
      </template>

      <h3 class="my-3">Proposed Additional Users</h3>
      <div v-for="(entry, index) in registrationIndividuals.additionalUsers.value" :key="index">
        <InfoBlock emptyValue="-" :model="entry.individual" :info="userBlock">
          <template #permissions-value>
            <template v-if="entry.individual.type === IndividualType.CLIENT_ADMIN"> Superuser </template>
            <template v-else-if="hasAnyPermissionsSet(entry.individual)"> Custom </template>
            <template v-else> None </template>
          </template>
        </InfoBlock>
      </div>
      <div v-if="registrationIndividuals.additionalUsers.value.length === 0">No additional users added.</div>
    </template>

    <template v-else>
      <h3 class="my-3">Authorised Signatory</h3>
      <template v-if="registrationIndividuals.owner.value">
        <ValidatedForm
          :fm="registrationIndividuals.owner.value.form"
          :validation.sync="registrationIndividuals.owner.value.validation"
          @form-event="onUserFormEvent($event, registrationIndividuals.owner.value)"
        />
        <h3 class="my-3">Current Address</h3>
        <RegistrationAddressForm
          use-three-columns
          :address.sync="registrationIndividuals.owner.value.individual.currentAddress"
          :validation.sync="primaryOwnerAddressValidation"
          :apiErrors="primaryOwnerAddressApiErrors?.fields?.currentAddress"
          :isCountryEditable="true"
        />
        <ReviewScreenPreviousAddressesForms
          use-three-columns
          class="mb-4"
          previous-addresses-parent-class=""
          v-if="isPrimaryOwnerUKResident && registrationIndividuals.owner.value.individual.currentAddress"
          :previousAddresses.sync="registrationIndividuals.owner.value.individual.previousAddresses"
          :validation.sync="primaryOwnerPreviousAddressesValidation"
          :currentAddressResidenceDate.sync="registrationIndividuals.owner.value.individual.currentAddress.residingFrom"
          :showErrorMessage="showErrorMessage"
          :apiErrors="primaryOwnerAddressApiErrors"
        />
        <hr />
      </template>

      <template v-if="registrationIndividuals.secondaryOwner.value">
        <div class="d-flex justify-content-between my-4">
          <h3>Secondary Authorised Signatory</h3>
          <RemoveButton
            class="mr-3"
            :removeButtonText="'Remove Secondary Authorised Signatory'"
            @remove="
              registrationIndividuals.secondaryOwner.value.individual.id &&
                registrationIndividuals.removeEntry(registrationIndividuals.secondaryOwner.value.individual.id)
            "
          />
        </div>
        <ValidatedForm
          :fm="registrationIndividuals.secondaryOwner.value.form"
          :validation.sync="registrationIndividuals.secondaryOwner.value.validation"
          @form-event="onUserFormEvent($event, registrationIndividuals.secondaryOwner.value)"
        />
        <h3 class="my-3">Current Address</h3>
        <RegistrationAddressForm
          use-three-columns
          :address.sync="registrationIndividuals.secondaryOwner.value.individual.currentAddress"
          :validation.sync="secondaryOwnerAddressValidation"
          :apiErrors="secondaryOwnerAddressApiErrors?.fields?.currentAddress"
          :isCountryEditable="true"
        />
        <ReviewScreenPreviousAddressesForms
          use-three-columns
          class="mb-4"
          previous-addresses-parent-class=""
          v-if="isSecondaryOwnerUKResident && registrationIndividuals.secondaryOwner.value.individual.currentAddress"
          :previousAddresses.sync="registrationIndividuals.secondaryOwner.value.individual.previousAddresses"
          :validation.sync="secondaryOwnerPreviousAddressesValidation"
          :apiErrors="secondaryOwnerAddressApiErrors"
          :currentAddressResidenceDate.sync="
            registrationIndividuals.secondaryOwner.value.individual.currentAddress.residingFrom
          "
          :showErrorMessage="showErrorMessage"
          :isSecondarySignatoryLabel="true"
        />
        <hr />
      </template>

      <template v-else>
        <p class="text-center font-italic text-secondary">No Secondary Authorised Signatory added.</p>
        <div class="buttons-holder px-0 pt-3 text-center">
          <VButton
            @click="registrationIndividuals.addEntry({ secondaryOwner: true })"
            class="btn-stroked mb-3"
            blurOnClick
          >
            Add Secondary Authorised Signatory
          </VButton>
        </div>
      </template>

      <h3 class="my-3">Proposed Additional Users</h3>
      <div
        v-for="(entry, index) in registrationIndividuals.additionalUsers.value"
        :key="`${entry.individual.id}-${index}`"
        class="additional-user-form"
      >
        <ValidatedForm
          :fm="entry.form"
          :validation.sync="entry.validation"
          @form-event="onUserFormEvent($event, entry)"
        >
          <template #memberForm.permissions>
            <ProposedUserPermissionsForm
              :individual="entry.individual"
              @update:individual="registrationIndividuals.updateEntry($event, entry)"
              :authorities="registrationIndividuals.authorities.value"
            />
          </template>
        </ValidatedForm>
        <div class="buttons-holder px-0 pt-3 text-center">
          <VButton
            @click="entry.individual.id && registrationIndividuals.removeEntry(entry.individual.id)"
            class="btn-danger-secondary mb-3"
            blurOnClick
          >
            Remove User
          </VButton>
        </div>
      </div>

      <div class="buttons-holder px-0 pt-3 text-center">
        <VButton
          @click="registrationIndividuals.addEntry"
          :disabled="registrationIndividuals.maxUsersAllowed.value"
          class="btn-stroked"
          blurOnClick
        >
          Add another User
        </VButton>

        <div v-if="registrationIndividuals.maxUsersAllowed.value" class="info-text text-muted text-small mt-2">
          *Only three proposed additional users can be created using this form. To add more users please contact
          {{ partnerName ? `the ${partnerName}` : 'our' }} support team.
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  label {
    font-weight: $font-weight-semibold;
    font-size: $font-size-base;
  }
}

.card-review-header {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;

  h2 {
    font-size: $h3-font-size;
    margin-bottom: 0;
  }

  .button-holder {
    margin-left: auto;
    display: inline-flex;
  }
  .btn {
    min-width: 7rem;
  }
}

.additional-user-form {
  @include themedBorderColor($color-box-border);
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
</style>
