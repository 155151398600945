<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { format } from 'date-fns';

@Component({
  render() {
    return null as any;
  },
})
export default class VersionAlert extends Vue {
  created() {
    const keyStrokes = new Map<string, boolean>();
    (this as any).onkeydown = (e: KeyboardEvent) => {
      if (e.target === document.body) {
        if (keyStrokes.get(e.key)) {
          return;
        }
        keyStrokes.set(e.key, true);
        if (['p', 'o', 'i'].reduce((v, c) => v && !!keyStrokes.get(c), true)) {
          this.$toast.clear('appInfo');
          this.$toast.info(
            `<br/><b>App version:</b>
              <br/>
              ${window.VUE_APP_VERSION}
              <br/>
              <b>Commit hash:</b>
              <br/>
              <p style="line-break: anywhere;">${window.VUE_APP_COMMIT_HASH}</p>
              <br/>
              <b>Build date & time:</b>
              <br/>
              ${format(new Date(window.VUE_APP_BUILD_TIME), 'dd/MM/yyyy HH:mm:ss')}`,
            {
              group: 'appInfo',
              isHtml: true,
            },
            {
              noAutoHide: true,
            }
          );
        }
      }
    };
    window.addEventListener('keydown', (this as any).onkeydown);

    (this as any).onkeyup = (e: KeyboardEvent) => {
      keyStrokes.set(e.key, false);
    };
    window.addEventListener('keyup', (this as any).onkeyup);
  }

  beforeDestroy() {
    window.removeEventListener('keydown', (this as any).onkeydown);
    window.removeEventListener('keyup', (this as any).onkeyup);
  }
}
</script>
