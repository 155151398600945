import { helpers, decimal, minLength } from '@vuelidate/validators';
import { checkParam, compareState, compareParam } from '../validators/paramCheck';
import {
  passwordLength,
  passwordLower,
  passwordNumber,
  passwordSpecialChar,
  passwordUpper,
} from '../validators/password';
import { ifTest, optional, requiredIfStateValue } from '../validators/optional';
import { fileTypeCheck, fileSizeCheck } from '../validators/file';
import { Field, FieldOption, FieldOptions, TreeOptionsBranch, FormModel, BaseFormModel } from '../interfaces/form';
import { ValidationRuleSet } from '../interfaces/validation';
import { date } from '../validators/date';
import { validIBANCountry } from '../validators/iban';
import { email } from '../validators/email';

export function financialAmountField(
  name = 'amount',
  title = 'Amount',
  state: FieldOptions = {},
  validators: ValidationRuleSet = { required: requiredIfStateValue(name) }
): Field {
  const out: Field = {
    name,
    state: {
      title,
      required: true,
      ...state,
    },
    fieldType: 'financialAmount',
    validators,
  };
  return out;
}

export function passwordField(
  name = 'password',
  title = 'Password',
  newPassword = false,
  state: FieldOptions = {},
  validators: ValidationRuleSet = { required: requiredIfStateValue(name) }
): Field {
  const out: Field = {
    name,
    state: {
      title,
      required: true,
      ...state,
    },
    fieldType: 'password',
    validators,
  };

  if (newPassword) {
    (out.validators as ValidationRuleSet).passwordLength = optional(passwordLength);
    (out.validators as ValidationRuleSet).passwordLower = optional(passwordLower);
    (out.validators as ValidationRuleSet).passwordUpper = optional(passwordUpper);
    (out.validators as ValidationRuleSet).passwordNumber = optional(passwordNumber);
    (out.validators as ValidationRuleSet).passwordSpecialChar = optional(passwordSpecialChar);
    (out.state as any).autocomplete = 'new-password';
  }
  return out;
}

export function otpField(
  name = 'otp',
  title = '6-digit code',
  state: FieldOptions = {},
  validators: ValidationRuleSet = { required: requiredIfStateValue(name) }
): Field {
  return {
    name,
    title,
    fieldType: 'otp',
    state: {
      required: true,
      defaultValue: [],
      length: 6,
      fromDataModelFn: (parent, formModel) => {
        formModel[name] = parent[name].split('') || [];
      },
      toDataModelFn: (val, parent) => {
        parent[name] = Array.isArray(val) ? val.join('') : '';
      },
      ...state,
    },
    validators: {
      validOtp: compareState(name, (val: string[], currentState) => {
        const test = Array.isArray(val) && val.filter((i) => !!i).length === currentState.length;
        return test;
      }),
      ...validators,
    },
  };
}

export function basisPointsField(
  name = 'partnerBasisPoints',
  title = 'Value',
  state: FieldOptions = {},
  validators: ValidationRuleSet = { required: requiredIfStateValue(name), decimal }
): Field {
  return {
    name,
    state: {
      title,
      required: true,
      number: true,
      class: 'half-width left',
      unit: 'basisPoints',
      defaultValue: 0,
      minValue: 0,
      maxValue: 10000,
      inputMode: 'decimal',
      ...state,
      errorMessages: {
        valueCheck: (field, model) => {
          if (model.$state.unit === 'basisPoints') {
            return `Value must be between ${model.$state.minValue} and ${model.$state.maxValue} basis points`;
          }
          return `Value must be between ${model.$state.minValue / 100}% and ${model.$state.maxValue / 100}%`;
        },
        ...state.errorMessages,
      },
    },
    fieldType: 'basisPoints',
    validators: {
      valueCheck: compareState(
        name,
        (val, currentState) => val >= currentState.minValue && val <= currentState.maxValue
      ),
      ...validators,
    },
  };
}

export function textField(
  name: string,
  title: string,
  state: FieldOptions = {},
  validators: ValidationRuleSet = { required: requiredIfStateValue(name) }
): Field {
  return {
    name,
    fieldType: 'text',
    state: {
      title,
      required: true,
      ...state,
    },
    validators,
  };
}

export function numberField(
  name: string,
  title: string,
  state: FieldOptions = {},
  validators: ValidationRuleSet = { required: requiredIfStateValue(name) }
): Field {
  return {
    name,
    fieldType: 'number',
    state: {
      title,
      required: true,
      ...state,
    },
    validators,
  };
}

export function emailField(
  name = 'email',
  title = 'Email',
  state: FieldOptions = {},
  validators: ValidationRuleSet = { required: requiredIfStateValue(name), email }
): Field {
  return textField(
    name,
    title,
    {
      pasteHandler(val: string) {
        // Attempt to clean any input pasted, as Email validation is quite strict
        // and copying from "weird" places will likely lead to issues with invisible characters
        return val.replace(/[^a-zA-Z0-9.!#$%&'*+/=?^_`{|}~\-@]/g, '');
      },
      ...state,
    },
    validators
  );
}

export function phoneField(
  name = 'phoneNumber',
  title = 'Telephone',
  state: FieldOptions = {},
  _validators?: ValidationRuleSet
): Field {
  let validators = _validators;
  if (!validators) {
    validators = {
      required: requiredIfStateValue(name),
      phone: optional(checkParam(name, 'valid')),
    };
  }
  return {
    name,
    state: {
      title,
      required: true,
      valid: true,
      ...state,
    },
    fieldType: 'phone',
    validators,
  };
}

export function ibanField(
  name = 'iban',
  title = 'IBAN',
  state: FieldOptions = {},
  _validators?: ValidationRuleSet
): Field {
  let validators = _validators;
  if (!validators) {
    validators = {
      required: requiredIfStateValue(name),
      iban: optional(checkParam(name, 'validIBAN')),
      ibanCC: optional(validIBANCountry(name)),
    };
  }
  return {
    name,
    state: {
      title,
      required: true,
      valid: true,
      ...state,
    },
    fieldType: 'iban',
    validators,
  };
}

export function selectField(
  name: string,
  title: string,
  options: FieldOption[] | (() => FieldOption[]),
  state: FieldOptions = {},
  validators: ValidationRuleSet = { required: requiredIfStateValue(name) }
): Field {
  return {
    name,
    fieldType: 'select',
    state: {
      title,
      required: true,
      options,
      ...state,
    },
    validators,
  };
}

export function radioField(
  name: string,
  title: string,
  options: FieldOption[],
  state: FieldOptions = {},
  validators: ValidationRuleSet = { required: requiredIfStateValue(name) }
): Field {
  return {
    name,
    fieldType: 'radio',
    state: {
      title,
      required: true,
      ...state,
    },
    options,
    validators,
  };
}

export function checkboxField(
  name: string,
  label: string,
  dated = false,
  state: FieldOptions = {},
  _validators?: ValidationRuleSet
): Field {
  let validators = _validators;

  if (validators === undefined) {
    validators = {
      mustAccept: helpers.withParams({ type: 'mustAccept' }, (val: any, model: FormModel) => {
        const field = model.$fields.find((f: BaseFormModel) => f.$name === name);
        if (field && field.$state.dated) {
          return !!(val || {})[field.$state.valuePropName];
        }
        return !!val;
      }),
    };
  }
  return {
    name,
    state: {
      label,
      dated,
      valuePropName: 'value',
      ...state,
    },
    fieldType: 'checkbox',
    validators,
  };
}

export function checkboxMultipleField(
  name: string,
  title: string,
  options: FieldOption[],
  state: FieldOptions = {},
  validators: ValidationRuleSet = {}
): Field {
  return {
    name,
    state: {
      title,
      options,
      valuePropName: 'value',
      defaultValue: [],
      ...state,
    },
    fieldType: 'checkboxMultiple',
    validators,
  };
}

export function dateField(
  name: string,
  title: string,
  state: FieldOptions = {},
  validators: ValidationRuleSet = {
    required: ifTest(requiredIfStateValue(name), (val) => !(val instanceof Date)),
    date: ifTest(date, (val) => val instanceof Date),
  }
): Field {
  return {
    name,
    state: {
      title,
      required: true,
      useDateTime: true,
      placeholder: 'Insert date...',
      inputMode: 'numeric',
      ...state,
    },
    fieldType: 'date',
    validators,
  };
}

export function monthSelectorField(
  name: string,
  title: string,
  state: FieldOptions = {},
  validators: ValidationRuleSet = {
    required: ifTest(requiredIfStateValue(name), (val) => !(val instanceof Date)),
    date: ifTest(date, (val) => val instanceof Date),
  }
): Field {
  return {
    name,
    state: {
      title,
      required: true,
      placeholder: 'Insert date...',
      ...state,
    },
    fieldType: 'monthSelector',
    validators,
  };
}

export function singleInputDateField(
  name: string,
  title: string,
  state: FieldOptions = {},
  validators: ValidationRuleSet = {
    required: ifTest(requiredIfStateValue(name), (val) => !(val instanceof Date)),
    date: ifTest(date, (val) => val instanceof Date),
  }
): Field {
  return {
    name,
    state: {
      title,
      required: true,
      useDateTime: true,
      placeholder: 'Insert date...',
      inputMode: 'numeric',
      ...state,
    },
    fieldType: 'singleInputDate',
    validators,
  };
}

export function tagsField(
  name: string,
  title: string,
  state: FieldOptions = {},
  validators: ValidationRuleSet = {
    tags: helpers.withAsync(
      helpers.withParams({ type: 'tags' }, (val: any, obj: FormModel) => {
        const found = obj.$fields.find((f: BaseFormModel) => f.$name === name);
        if (!found) {
          return false;
        }
        return new Promise<boolean>((resolve) => {
          const checkVal = () => {
            const validTags = found.$state.validTags;
            if (typeof validTags !== 'boolean') {
              setTimeout(checkVal, 50);
            } else if (validTags === true) {
              resolve(true);
            } else if (validTags === false) {
              resolve(false);
            }
          };
          checkVal();
        });
      })
    ),
    required: requiredIfStateValue(name),
  }
): Field {
  return {
    name,
    state: {
      title,
      toDataModelFn: (val, parent) => {
        parent[name] = val || [];
      },
      ...state,
    },
    fieldType: 'tags',
    validators,
  };
}

export function textAreaField(
  name: string,
  title: string,
  state: FieldOptions = {},
  validators: ValidationRuleSet = {
    required: requiredIfStateValue(name),
  }
): Field {
  return {
    name,
    state: {
      title,
      required: true,
      ...state,
    },
    fieldType: 'textArea',
    validators,
  };
}

export function currencyExchangeField(
  name = 'currency',
  title = 'Currency',
  state: FieldOptions = {
    sellCurrencyLabel: 'What foreign currency do you have risk to?',
    buyCurrencyLabel: 'Home currency',
    minimumValue: 0,
    maximumValue: Infinity,
    hideErrorMessages: true,
  },
  validators: ValidationRuleSet = {
    required: helpers.withParams(
      { type: 'required' },
      (val: any) =>
        !!(
          val &&
          val.buyCurrency &&
          typeof val.amount === 'number' &&
          val.amount > 0 &&
          val.tradeDirection &&
          val.sellCurrency
        )
    ),
    minimumValue: compareParam(
      name,
      'minimumValue',
      (val, compare) => typeof compare !== 'number' || Number.isNaN(compare) || val.amount >= compare
    ),
    maximumValue: compareParam(
      name,
      'maximumValue',
      (val, compare) => typeof compare !== 'number' || Number.isNaN(compare) || val.amount <= compare
    ),
  }
): Field {
  return {
    name,
    state: {
      title,
      toDataModelFn(value, dataModel) {
        Object.assign(dataModel, value);
      },
      fromDataModelFn(dataModel, formModel) {
        formModel[name] = {
          amountType: dataModel.amountType,
          amount: dataModel.amount,
          sellCurrency: dataModel.sellCurrency,
          tradeDirection: dataModel.tradeDirection,
          buyCurrency: dataModel.buyCurrency,
        };
      },
      ...state,
    },
    fieldType: 'currencyExchange',
    validators,
  };
}

export function fileField(
  name: string,
  title: string,
  state: FieldOptions = {},
  validators: ValidationRuleSet = {
    required: requiredIfStateValue(name),
    minLength: minLength(1),
    fileType: fileTypeCheck(name),
    fileSize: fileSizeCheck(name),
  }
): Field {
  return {
    name,
    title,
    fieldType: 'files',
    state: {
      required: true,
      maxFiles: 1,
      maxSize: 10485760,
      acceptedFileTypes: [],
      fileIcon: 'file',
      ...state,
    },
    validators,
  };
}

export function sliderField(
  name: string,
  title: string,
  state: FieldOptions = {},
  validators: ValidationRuleSet = {
    required: requiredIfStateValue(name),
  }
): Field {
  return {
    name,
    state: {
      title,
      min: 0,
      max: 10,
      required: true,
      interval: 1,
      defaultValue: 0,
      marks: (val: number) => ({ label: `${val}` }),
      ...state,
    },
    fieldType: 'slider',
    validators,
  };
}

export function choicesField(
  name: string,
  title: string,
  options: TreeOptionsBranch,
  state: FieldOptions = {},
  validators: ValidationRuleSet = {
    final: checkParam(name, 'final'),
  }
): Field {
  return {
    name,
    state: {
      title,
      hideErrors: true,
      ...state,
    },
    validators,
    options,
    fieldType: 'choiceTree',
  };
}

export function nullField(name: string, state: FieldOptions = {}): Field {
  return {
    name,
    state: {
      toDataModelFn() {},
      fromDataModelFn() {},
      ...state,
    },
    fieldType: 'custom',
  };
}

export function separator(state: FieldOptions = {}) {
  return nullField(state.name ?? 'separator-field', { class: 'separator', ...state });
}

export function formTitle(title: string, state: FieldOptions = {}) {
  return nullField(state.name ?? 'separator-field', { title, class: 'title', ...state });
}

export function formText(title: string, state: FieldOptions = {}) {
  return nullField(state.name ?? 'separator-field', { title, class: 'text', ...state });
}
