import { SessionData, SessionUser } from '../models/user/sessionUser';
import { HttpService, HttpBasedService, HttpOptions, HttpRequestOptions } from 'ah-requests';
import { User, MFARequest, MFAResponse, MFAType } from '../models';
import { ExpiryTime } from '../models/expiry';

export class AuthenticationService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'authenticationService' } },
      },
    });
  }

  public login(data: { email: string; password: string }, options?: Partial<HttpOptions<SessionData>>) {
    return this.post<SessionData>(
      `${this.baseUrl}session`,
      {
        accountId: data.email,
        accountSecret: data.password,
      },
      {
        options: {
          ...options,
          skipAuth: true,
        },
        axiosConfig: {
          withCredentials: true,
        },
      }
    );
  }

  public getADLoginCredentials(options?: Partial<HttpOptions<{ authorizationUrl: string }>>) {
    return this.get<{ authorizationUrl: string }>(`${this.baseUrl}session/azure`, {
      options: {
        ...options,
        skipAuth: true,
      },
      axiosConfig: {
        withCredentials: true,
      },
    });
  }

  public loginWithAD(code: string, options?: Partial<HttpOptions<SessionData>>) {
    return this.post<SessionData>(
      `${this.baseUrl}session/azure`,
      {
        code,
      },
      {
        options: {
          ...options,
          skipAuth: true,
        },
        axiosConfig: {
          withCredentials: true,
        },
      }
    );
  }

  public getSession(options?: HttpRequestOptions<SessionUser>) {
    return this.get<SessionUser>(`${this.baseUrl}session`, {
      options: {
        ...options?.options,
      },
      axiosConfig: {
        withCredentials: true,
        ...options?.axiosConfig,
      },
    });
  }

  public refreshOtp(options?: Partial<HttpOptions<ExpiryTime & { tokenType?: MFAType }>>) {
    return this.put<ExpiryTime & { tokenType?: MFAType }>(`${this.baseUrl}session/otp`, undefined, {
      options,
      axiosConfig: {
        withCredentials: true,
      },
    });
  }

  public otp(otp: string, options?: Partial<HttpOptions<SessionData>>) {
    return this.post<SessionData>(`${this.baseUrl}session/otp`, undefined, {
      options: {
        errors: {
          silent: true,
        },
        ...options,
      },
      axiosConfig: {
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          'x-ah-otp': otp,
        },
        withCredentials: true,
      },
    });
  }

  public mfa(value: string, mfa: MFARequest, options?: Partial<HttpOptions<MFAResponse>>) {
    return this.put<MFAResponse>(`${this.baseUrl}mfa`, { value, ...mfa }, { options });
  }

  public refreshMfa(mfa: MFARequest, options?: Partial<HttpOptions<MFAResponse>>) {
    return this.post<MFAResponse>(`${this.baseUrl}mfa`, mfa, {
      options: {
        errors: {
          silent: true,
        },
        ...options,
      },
    });
  }

  public redeemRegistrationMFA() {
    return this.put<SessionData>(`${this.baseUrl}mfa/registration`);
  }

  public refreshSession(refreshToken: string, options?: Partial<HttpOptions<SessionData>>) {
    return this.put<SessionData>(
      `${this.baseUrl}session`,
      { refreshToken },
      {
        options: {
          errors: {
            silent: true,
          },
          ...options,
          skipAuth: true,
        },
        axiosConfig: {
          withCredentials: true,
        },
      }
    );
  }

  public generateOAuthCode(
    props: {
      clientId: string;
      redirectUri: string;
    },
    options?: Partial<HttpRequestOptions<{ code: string }>>
  ) {
    return this.post<{ code: string }>(`${this.baseUrl}oauth/authorize`, props, options);
  }

  // FIXME this belongs to UserService, but needs to be exposed for now
  public getUser(userId: string, options?: Partial<HttpOptions<User>>) {
    return this.get<User>(`${this.baseUrl}users/${userId}`, { options });
  }

  public logout(options?: Partial<HttpOptions<any>>) {
    return this.delete<void>(`${this.baseUrl}session`, {
      options,
      axiosConfig: {
        withCredentials: true,
      },
    });
  }
}
