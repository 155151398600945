import { Products } from '../products';
import { DeletableObject } from '../rest';

export enum LiquidityProviderInstrumentType {
  SPOT = 'SPOT',
  FORWARD = 'FORWARD',
}

export enum LiquidityProviderType {
  SUCDEN = 'SUCDEN',
  CURRENCY_CLOUD = 'CURRENCY_CLOUD',
}

export const liquidityProviderTypeToHuman: Record<LiquidityProviderType, string> = {
  SUCDEN: 'Sucden',
  CURRENCY_CLOUD: 'Currency Cloud',
};

export function formatLiquidityProviders(liquidityProvider?: string, defaultStr = '') {
  if (!liquidityProvider) {
    return defaultStr;
  }
  return liquidityProviderTypeToHuman[liquidityProvider as LiquidityProviderType] || defaultStr;
}

export interface LiquidityProvider extends Partial<DeletableObject> {
  id: string;
  clientId: string;
  partnerId: string;
  liquidityProvider: LiquidityProviderType;
  instrumentType: Products;
  enabled?: boolean;
}
