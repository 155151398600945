<script setup lang="ts">
import { computed, onBeforeMount, reactive, watch } from 'vue';
import PersonalInformationForm from './forms/individual/PersonalInformationForm.vue';
import RouteProtectorModal from 'ah-common-lib/src/common/components/route/RouteProtectorModal.vue';
import RegistrationProgressFlow from './RegistrationProgressFlow.vue';
import AccountVerificationForm from './forms/common/AccountVerificationForm.vue';
import CountryOfIncorporation from './forms/common/CountryOfIncorporation.vue';
import TradingProfileForm from './forms/common/TradingProfileForm.vue';
import RegistrationDocumentsForm from './forms/common/RegistrationDocumentsForm.vue';
import {
  calculateCurrentApplicantionFlow,
  makeIndividualRegistrationSteps,
  RegistrationStepKeys,
} from '@/app/helpers/registration/flow';
import { loadFlowData } from '@/app/helpers/registration/requests';
import {
  BaseRegistrationModel,
  ClientType,
  EntityAddressHistory,
  EntityTypeAddress,
  getAddressHistoryError,
} from 'ah-api-gateways';
import { isEmpty } from 'lodash';
import { useRouter } from 'vue-router/composables';
import { WizardConfig } from 'ah-common-lib/src/models/progress';
import { useAuthStore } from '@/app/store/authStore';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { catchError, mergeMap } from 'rxjs/operators';
import { from, throwError } from 'rxjs';
import { HttpError, waitForEntityChange } from 'ah-requests';
import { useToast } from 'ah-common-lib/src/toast';

const emit = defineEmits<{
  (e: 'back-to-type'): void;
  (e: 'page-changed', page: number): void;
  (e: 'registered'): void;
}>();

const props = defineProps({
  /**
   * Applicant email address to prefill in forms, if any
   */
  email: { type: String, default: '' },
  referralId: { type: String, default: '' },
});

const authStore = useAuthStore();

const requestManager = useRequestManager().manager;

const router = useRouter();

const toast = useToast();

const state = reactive<{
  model: Partial<BaseRegistrationModel>;
  registrationFlow: WizardConfig<RegistrationStepKeys>;
  registrationSessionId: string;
  errorState: boolean;
  historyAddresses?: Partial<EntityAddressHistory>;
}>({
  model: {
    clientType: ClientType.INDIVIDUAL,
    // typing as any - Typescript expects a full AccountAccess object, but our system works well with a partial object
    applicant: {
      email: props.email,
      referralId: props.referralId,
    } as any,
    referralId: props.referralId,
  },
  registrationFlow: makeIndividualRegistrationSteps(),
  registrationSessionId: authStore.userData?.id || '',
  errorState: false,
});

const anyDirty = computed(() => !isEmpty(state.model));

const canExitFlow = computed(() => {
  return !anyDirty.value || hasSessionChanged.value || state.errorState;
});

const hasSessionChanged = computed(() => {
  return authStore.userData?.id !== state.registrationSessionId;
});

const services = getServices();

function isActive(key: RegistrationStepKeys) {
  return state.registrationFlow.active === state.registrationFlow.steps.findIndex((step) => step.key === key);
}

function submitRegistrationRequest(clientId: string) {
  return services.compliance.submitClientQuestionnaire(clientId);
}

/**
 * We watch for ANY changes to the user currently in session, as any change should result in exiting the form immediately
 */
watch(
  () => authStore.userData?.id,
  () => {
    if (!state.registrationSessionId && authStore.hasRegistrationSession) {
      state.registrationSessionId = authStore.userData?.id || '';
    } else if (isActive(RegistrationStepKeys.PERSONAL_DETAILS) && !authStore.userData) {
      // do nothing if we are on Personal details and have no session
      // (this handles the back button behaviour clearing user token)
    } else if (state.registrationSessionId !== authStore.userData?.id) {
      router.push('/');
    }
  }
);

watch(
  () => state.registrationFlow.active,
  () => {
    emit('page-changed', state.registrationFlow.active);
  },
  { immediate: true }
);

function proceed() {
  state.registrationFlow.steps[state.registrationFlow.active].state = 'valid';
  if (state.registrationFlow.active === state.registrationFlow.steps.length - 1) {
    requestManager
      .currentOrNew('submitRegistration', submitRegistrationRequest(authStore.loggedInIdentity!.client!.id))
      .subscribe(() => emit('registered'));
    return;
  } else {
    state.registrationFlow.active++;
  }
}

function back() {
  state.registrationFlow.active--;
  if (isActive(RegistrationStepKeys.PERSONAL_DETAILS)) {
    state.registrationSessionId = '';
    authStore.clearTokenUser();
  }
}

function loadData() {
  if (authStore.hasRegistrationSession) {
    requestManager.newPromise('loadData', loadFlowData(state.model, requestManager)).then((res) => {
      const { model, client, complianceCase, individuals } = res;
      state.model = model;
      state.registrationFlow = calculateCurrentApplicantionFlow(
        state.registrationFlow,
        client,
        complianceCase,
        individuals
      );
    });
  } else {
    state.registrationFlow.active = 0;
  }
}

function createClient() {
  requestManager
    .sameOrCancelAndNew(
      'createClient',
      services.registration.registerClient({}).pipe(
        // Waiting for session update until a client is added to the session data (due to CQRS delay)
        mergeMap(() =>
          waitForEntityChange(
            () =>
              from(
                useAuthStore()
                  .refreshSession()
                  .then(() => authStore.userData)
              ),
            (session) => !!session?.individual?.client?.id
          )
        ),
        mergeMap((session) => {
          const historyAddresses = {
            ...state.historyAddresses,
            entityId: session?.individual?.id,
            type: EntityTypeAddress.INDIVIDUAL,
          } as EntityAddressHistory;

          return requestManager.currentOrNew(
            'submitAddressHistory',
            services.compliance.createAddressHistory(historyAddresses).pipe(
              catchError((error: HttpError) => {
                const errorMessage = getAddressHistoryError(error);

                if (errorMessage) {
                  toast.error(errorMessage);
                }

                return throwError(error);
              })
            )
          );
        })
      )
    )
    .subscribe(proceed);
}

function onLoginError() {
  state.errorState = true;
  toast.error('An error occurred while attempting to register your account');
  router.push('/');
}

onBeforeMount(loadData);
</script>

<template>
  <div x-test-name="personal-registration">
    <RouteProtectorModal
      ref="formDismissModal"
      :allowChange="canExitFlow"
      :allowSubpaths="false"
      :allowQueryChange="false"
      class="exit-protected-route"
      centered
      title="Changes not saved"
    >
      <p>There are unsaved changes. Are you sure you want to continue?</p>
    </RouteProtectorModal>

    <SimpleCenteredPage fullHeight>
      <BoxGrid alignV="start">
        <BoxGridItem class="mb-5" xl="8" lg="7" md="6" sm="12">
          <RegistrationProgressFlow :config="state.registrationFlow" />
        </BoxGridItem>
      </BoxGrid>

      <BoxGrid alignV="start">
        <BoxGridItem xl="6" lg="7" md="8" sm="12" v-if="isActive(RegistrationStepKeys.CLIENT_COUNTRY_CHECK)">
          <CountryOfIncorporation
            :model.sync="state.model"
            @proceed="proceed"
            heading="Country Of Residence"
            :clientType="ClientType.INDIVIDUAL"
          />
        </BoxGridItem>
        <BoxGridItem xl="6" lg="7" md="8" sm="12" v-if="isActive(RegistrationStepKeys.PERSONAL_DETAILS)">
          <PersonalInformationForm
            :model.sync="state.model"
            @proceed="proceed"
            @update:historyAddresses="state.historyAddresses = $event"
          />
        </BoxGridItem>

        <BoxGridItem xl="6" lg="7" md="8" sm="12" v-else-if="isActive(RegistrationStepKeys.ACCOUNT_VERIFICATION)">
          <AccountVerificationForm
            :model="state.model"
            @back="back"
            @proceed="createClient"
            @login-error="onLoginError"
          />
        </BoxGridItem>

        <BoxGridItem xl="6" lg="7" md="8" sm="12" v-else-if="isActive(RegistrationStepKeys.TRADING_DETAILS)">
          <TradingProfileForm :model.sync="state.model" @proceed="proceed" />
        </BoxGridItem>

        <BoxGridItem xl="6" lg="7" md="8" sm="12" v-else-if="isActive(RegistrationStepKeys.DOCUMENTATION)">
          <RegistrationDocumentsForm :model.sync="state.model" @proceed="proceed" />
        </BoxGridItem>
      </BoxGrid>
    </SimpleCenteredPage>
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .field-group-wrapper {
    margin-bottom: 2rem;
  }
  h2 {
    @include themedTextColor($color-primary);
  }
}
</style>
