<template>
  <VRow class="data-row">
    <VCol class="label-col semi-bold" v-bind="labelVColAttrs">
      <slot name="label-icon" />
      <slot name="label">{{ label }}:</slot>
    </VCol>
    <VCol class="value-col" v-bind="valueVColAttrs">
      <slot />
    </VCol>
  </VRow>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import kebabCase from 'lodash/kebabCase';
import mapKeys from 'lodash/mapKeys';

@Component
export default class DataRow extends Vue {
  @Prop({ default: '' }) label!: string;

  get labelVColAttrs() {
    return {
      cols: 4,
      ...mapKeys(this.$attrs, (v, k) => kebabCase(k)),
    } as any;
  }

  get valueVColAttrs() {
    const attrs: any = {};
    ['cols', 'sm', 'md', 'lg', 'xl'].forEach((key) => {
      if (typeof this.labelVColAttrs[key] === 'number' || typeof this.labelVColAttrs[key] === 'string') {
        attrs[key] = 12 - Number(this.labelVColAttrs[key]);
      }
    });
    return attrs;
  }
}
</script>

<style lang="scss" scoped>
.data-row {
  word-break: break-word;
}
</style>
