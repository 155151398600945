<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { NavigationGuardNext, RawLocation, Route } from 'vue-router';

export interface IRouteProtectorOptions {
  allowSubpaths: boolean;
  allowQueryChange: boolean;
}

/**
 * Class to extend when a component needs to protect against route changes
 */
@Component
export default class RouteProtector extends Vue {
  protected protectorOptions: IRouteProtectorOptions = {
    allowSubpaths: true,
    allowQueryChange: true,
  };

  private forceAllowChange = false;

  private currentPath = '';

  private beforeEachUnsubscriber!: any;

  private beforeUnloadListener(event: BeforeUnloadEvent) {
    if (!this.canLeave()) {
      event.preventDefault();
      event.returnValue = '';
    }
  }

  @Watch('protectorOptions.allowSubpaths', { immediate: true })
  onAllowSubpathsChange() {
    if (this.protectorOptions.allowSubpaths) {
      /**
       * Retrieves the closest match that rendered this or a direct parent (via <RouterView>)
       * This is necessary to allow RouteProtector behavior to be consistent
       * for both View and non-View components alike
       */
      const route = [...this.$route.matched].reverse().find((r) =>
        Object.keys(r.instances).find((k) => {
          let currComponent: Vue | null = this as Vue;
          while (currComponent) {
            if (r.instances[k] === currComponent) {
              return true;
            }
            currComponent = currComponent.$parent;
          }
          return false;
        })
      );
      this.currentPath = route?.path || '';
    } else {
      this.currentPath = '';
    }
  }

  beforeMount() {
    this.beforeEachUnsubscriber = this.$router.beforeResolve((to, from, next) => {
      if (this.forceAllowChange) {
        next();
      } else if (this.protectorOptions.allowSubpaths && to.matched.some((i) => i.path === this.currentPath)) {
        next();
      } else if (this.protectorOptions.allowQueryChange && to.path === from.path) {
        next();
      } else {
        this.routeChangeCheck(to, from, next);
      }
      return undefined;
    });
    window.addEventListener('beforeunload', this.beforeUnloadListener);
  }

  beforeDestroy() {
    this.beforeEachUnsubscriber();
    window.removeEventListener('beforeunload', this.beforeUnloadListener);
  }

  protected routeChangeCheck(to: Route, from: Route, next: NavigationGuardNext) {
    if (this.canLeave()) {
      return next();
    }
    next(false);
    return undefined;
  }

  protected canLeave() {
    return true;
  }

  protected forcePush(location: RawLocation) {
    this.forceAllowChange = true;
    this.$router.push(location);
    setTimeout(() => {
      this.forceAllowChange = false;
    });
  }

  protected forceReplace(location: RawLocation) {
    this.forceAllowChange = true;
    this.$router.replace(location);
    setTimeout(() => {
      this.forceAllowChange = false;
    });
  }
}
</script>
