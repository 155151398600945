<template>
  <BoxGrid alignH="start">
    <BoxGridBlock
      :class="[animatedState.wideList ? 'with-wide-list' : 'with-narrow-list', listingBlockClass]"
      v-bind="animatedState.wideList ? wideListBlockProps : narrowListBlockProps"
      animateTransitions
      @transition-end="afterAnimation"
    >
      <slot name="listing" v-bind="{ animatedState }" />
    </BoxGridBlock>

    <Transition name="slide-and-fade" @after-leave="afterAnimation">
      <BoxGridItem v-if="animatedState.showInfo" v-bind="infoBlockProps">
        <slot name="sub-view" v-bind="{ animatedState }" />
      </BoxGridItem>
    </Transition>
  </BoxGrid>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';

export interface AnimatedListingWithSubViewState {
  wideList: boolean;
  showInfo: boolean;
}

@Component
export default class AnimatedListingWithSubView extends Vue {
  @Prop({ required: true }) showInfo!: boolean;

  @Prop({ required: false }) listingBlockClass?: string;

  @Prop({ default: () => ({}) }) wideListBlockProps!: any;

  @Prop({ default: () => ({}) }) narrowListBlockProps!: any;

  @Prop({ default: () => ({}) }) infoBlockProps!: any;

  private animatedState = {
    wideList: true,
    showInfo: false,
  };

  beforeMount() {
    this.animatedState = {
      wideList: !this.showInfo,
      showInfo: this.showInfo,
    };
  }

  afterAnimation() {
    this.animatedState.wideList = !this.showInfo;
    this.animatedState.showInfo = this.showInfo;
  }

  @Watch('animatedState', { immediate: true })
  onAnimatedStateChange() {
    this.$emit('update:animatedState', this.animatedState);
  }

  @Watch('showInfo')
  toggleAnimationOnWalletChange(newVal: any, oldVal: any) {
    if (oldVal && !newVal) {
      this.animatedState.showInfo = false;
    } else if (!oldVal && newVal) {
      this.animatedState.wideList = false;
    }
  }
}
</script>
