import { HttpBasedService, HttpOptions, HttpService } from 'ah-requests';
import { LiquidityProviderInstrumentType, SingleCurrency } from '../models';

export class FxLiquidityProvidersService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'referenceService' } },
      },
    });
  }

  public listCurrencies(
    lpInstrumentType: LiquidityProviderInstrumentType,
    oboClientId?: string,
    options?: Omit<HttpOptions<SingleCurrency[]>, 'cache'>
  ) {
    const headers: Record<string, string> = {};
    if (oboClientId) {
      headers['x-ah-on-behalf-of'] = oboClientId;
    }

    return this.get<SingleCurrency[]>(`${this.baseUrl}liquidity-providers/currencies/${lpInstrumentType}`, {
      axiosConfig: { headers },
      options: {
        ...options,
        cache: {
          type: 'use',
          cacheKey: `liquidityproviderscurrenciesfields${lpInstrumentType}`,
          itemKey: `liquidityproviderscurrencies${lpInstrumentType}`,
        },
      },
    });
  }
}
