<template>
  <VButton class="back-button transparent" :class="{ small: small !== false }" v-on="$listeners">
    <IconArrowRight :class="['back-arrow', { 'with-text': showLabel }]" />
    <span v-if="showLabel">{{ label }}</span>
  </VButton>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class BackButton extends Vue {
  @Prop({ default: 'Back to list' }) label!: string;

  @Prop({ default: false }) narrow!: string | boolean;

  @Prop({ default: false }) small!: string | boolean;

  get showLabel() {
    return this.narrow === false && !!this.label;
  }
}
</script>

<style lang="scss" scoped>
.back-button {
  text-transform: uppercase;
  font-size: $font-size-xs;
  font-weight: 700;

  &.small > .label {
    span {
      font-size: $font-size-xxs;
    }
    svg {
      font-size: $font-size-sm;
    }
  }

  .back-arrow {
    transform: rotate(180deg);
    font-size: 2.5em;
    position: relative;
    top: -0.05em;

    &.with-text {
      margin-right: 0.2em;
    }
  }
}
</style>
