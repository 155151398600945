<script setup lang="ts">
import { ref, computed, watch, onBeforeMount } from 'vue';
import { toDataModel, setState, getChildModel, updateModel, setApiErrorMessages } from 'ah-common-lib/src/form/helpers';
import {
  AboutUsReference,
  ClientType,
  UserCreationRequest,
  BaseRegistrationModel,
  aboutUsReferenceOptions,
  AddressHistoryItem,
  CurrentAddressHistoryItem,
  EntityAddressHistory,
  checkAddressHistoryValidity,
  Address,
  BaseIndividualUserRegistrationRequest,
} from 'ah-api-gateways';
import { SecurePasswordChecker } from 'ah-common-lib/src/form/components';
import { FormEvent, FormValidation, CompoundValidation } from 'ah-common-lib/src/form/interfaces';
import RegistrationAddressForm from '../common/RegistrationAddressForm.vue';
import OnboardingPreviousAddressesForms from '../common/OnboardingPreviousAddressesForms.vue';
import { accountAccessFM } from '@/app/helpers/registration/forms';
import { cloneDeep, sortBy } from 'lodash';
import { submitUser } from '@/app/helpers/registration/requests';
import { useFEFeatureFlag } from 'ah-common-stores';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useToast } from 'ah-common-lib/src/toast';
import { useSettingsStore } from '@/app/store/settingsModule';
import { getServices } from '@/app/services';
import { catchError, mergeMapTo } from 'rxjs/operators';
import { HttpError, PayloadErrorData } from 'ah-requests';
import { constructPayloadErrors } from 'ah-requests/helpers/apiErrors';

const props = defineProps<{
  model: Partial<BaseRegistrationModel>;
}>();

const emit = defineEmits({
  'update:model': (_value: Partial<BaseRegistrationModel>) => true,
  'update:historyAddresses': (_value: Partial<EntityAddressHistory>) => true,
  proceed: () => true,
  failure: () => true,
});

const services = getServices();

const toast = useToast();

const settingsStore = useSettingsStore();

const { manager: requestManager } = useRequestManager();

const dobInOnboardingFeatureActive = useFEFeatureFlag('dobInOnboardingFeatureActive');

const accountAccessForm = ref(
  accountAccessFM({
    isCompanyApplicant: false,
    useDOB: dobInOnboardingFeatureActive.value,
  })
);
const accountAccessValidation = ref<FormValidation | null>(null);
const currentAddress = ref<CurrentAddressHistoryItem>({ residingFrom: '' } as CurrentAddressHistoryItem);
const addressValidation = ref<FormValidation | null>(null);
const addressHistoryValidation = ref<CompoundValidation | null>(null);
const previousAddresses = ref<AddressHistoryItem[]>([]);
const showErrorMessage = ref(false);
const apiErrors = ref<PayloadErrorData<UserCreationRequest>>();

const isUKResident = computed(() => currentAddress.value?.countryCode === 'GB');

const addressApiError = computed(
  () =>
    (apiErrors.value?.fields?.registrationData as PayloadErrorData<BaseIndividualUserRegistrationRequest>)?.fields
      ?.address
);

const isAddressesHistoryValid = () => {
  if (!isUKResident.value) {
    return true;
  }
  const addressHistoryValidity = checkAddressHistoryValidity({
    currentAddress: currentAddress.value || {},
    previousAddresses: previousAddresses.value,
  });

  return addressHistoryValidity.age && addressHistoryValidity.gaps;
};

const calculatedModel = computed((): Partial<BaseRegistrationModel> => {
  const { referenceDescription, referenceSelection, ...applicant } = toDataModel(accountAccessForm.value);
  return {
    ...props.model,
    reference: referenceSelection === AboutUsReference.OTHER ? referenceDescription : referenceSelection,
    applicant: {
      ...props.model.applicant,
      ...applicant,
    },
    address: { ...props.model.address!, ...currentAddress.value },
  };
});

const historyAddresses = computed(
  (): Partial<EntityAddressHistory> => ({
    currentAddress: currentAddress.value,
    previousAddresses: previousAddresses.value,
  })
);

const userCreationRequest = computed((): UserCreationRequest | null => {
  if (!currentAddress.value) return null;
  let { ...address } = currentAddress.value;

  return {
    applicant: calculatedModel.value.applicant!,
    registrationData: {
      ...(dobInOnboardingFeatureActive.value ? { birthDate: calculatedModel.value.applicant!.birthDate } : {}),
      questionnaireAnswer: calculatedModel.value.reference?.length ? calculatedModel.value.reference : undefined,
      address,
      nationality: calculatedModel.value.applicant?.nationality!,
      referralId: calculatedModel.value.referralId,
    },
  };
});

const isFormInvalid = computed(
  () =>
    !!accountAccessValidation.value?.$invalid ||
    !!addressValidation.value?.$invalid ||
    !!addressHistoryValidation.value?.$invalid
);

const emitUpdate = () => {
  emit('update:model', calculatedModel.value);
};

const onAddressChange = (value: Partial<Address>) => {
  currentAddress.value = {
    ...props.model.address,
    ...(value as CurrentAddressHistoryItem),
    countryCode: props.model.address?.countryCode!,
  };
  delete (currentAddress.value as any).countryName;

  emitUpdate();
};

const onFormEvent = (formEvent: FormEvent) => {
  if (formEvent.event === 'form-field-set-value') {
    setState(formEvent.model, 'errors', []);

    if (formEvent.model.$name === 'referenceSelection') {
      checkReferenceDescriptionDisplay();
      accountAccessForm.value.referenceDescription = '';
      if (accountAccessValidation.value?.reference) {
        accountAccessValidation.value.reference.$reset();
      }
    }
    emitUpdate();
  }
};

const checkReferenceDescriptionDisplay = () => {
  const field = getChildModel(accountAccessForm.value, 'referenceDescription')!;
  setState(field, 'hidden', accountAccessForm.value.referenceSelection !== AboutUsReference.OTHER);
  setState(field, 'required', accountAccessForm.value.referenceSelection === AboutUsReference.OTHER);
};

const submit = () => {
  if (!isAddressesHistoryValid()) {
    toast.error(
      'Please review address information. UK residents must provide addresses spanning back 3 years, with no gaps.'
    );
    showErrorMessage.value = true;
    return;
  }
  showErrorMessage.value = false;

  setState(accountAccessForm.value, 'errors', [], true);
  apiErrors.value = undefined;

  requestManager
    .currentOrNew(
      'submitPersonalDetails',
      services.compliance.validateAddressHistory(historyAddresses.value).pipe(
        catchError((e) => {
          // TODO populate errors using PayloadErrorData
          throw e;
        }),
        mergeMapTo(
          submitUser(
            props.model.clientType ?? ClientType.INDIVIDUAL,
            userCreationRequest.value!,
            accountAccessForm.value
          )
        )
      )
    )
    .subscribe(
      () => {
        emit('update:historyAddresses', historyAddresses.value);
        emit('proceed');
      },
      (error: HttpError) => {
        apiErrors.value = constructPayloadErrors<UserCreationRequest>(error.response!.data);

        if (apiErrors.value.fields?.applicant) {
          setApiErrorMessages(apiErrors.value.fields?.applicant, accountAccessForm.value);
        }
        emit('failure');
      }
    );
};

onBeforeMount(() => {
  const nationalityField = getChildModel(accountAccessForm.value, 'nationality');
  if (nationalityField) {
    setState(
      nationalityField,
      'options',
      sortBy(
        settingsStore.allCountries.map((c) => ({
          value: c.cc,
          label: c.nationality,
        })),
        (i) => i.label
      )
    );
  }
});

watch(
  [() => props.model, dobInOnboardingFeatureActive],
  () => {
    updateModel(accountAccessForm.value, { ...props.model?.applicant }, undefined, true);
    currentAddress.value = cloneDeep(props.model?.address ?? ({ residingFrom: '' } as any));

    if (typeof props.model?.reference === 'string') {
      if (
        aboutUsReferenceOptions
          .map((obj) => obj.value)
          .filter((v) => v !== AboutUsReference.OTHER)
          .includes(props.model.reference)
      ) {
        accountAccessForm.value.referenceSelection = props.model.reference;
      } else {
        accountAccessForm.value.referenceSelection = AboutUsReference.OTHER;
        accountAccessForm.value.referenceDescription = props.model.reference;
      }
      checkReferenceDescriptionDisplay();
    }

    const nationalityField = getChildModel(accountAccessForm.value, 'nationality');
    if (nationalityField) {
      setState(
        nationalityField,
        'options',
        sortBy(
          settingsStore.allCountries.map((c) => ({
            value: c.cc,
            label: c.nationality,
          })),
          (i) => i.label
        )
      );
    }
  },
  { immediate: true }
);
</script>

<template>
  <div x-test-name="personal-information-form">
    <h2>Personal Details</h2>
    <ValidatedForm :fm="accountAccessForm" :validation.sync="accountAccessValidation" @form-event="onFormEvent">
      <template #accountAccess.phoneNumber:before>
        <h3 class="current-account-heading">Current Address</h3>
        <RegistrationAddressForm
          :address="currentAddress"
          @update:address="onAddressChange"
          :validation.sync="addressValidation"
          :isCountryEditable="false"
          :apiErrors="addressApiError"
        />
        <OnboardingPreviousAddressesForms
          class="mb-4"
          v-if="isUKResident"
          :currentAddressResidenceDate.sync="currentAddress.residingFrom"
          :previousAddresses.sync="previousAddresses"
          :validation.sync="addressHistoryValidation"
          :showErrorMessage="showErrorMessage"
        />
      </template>
      <template #accountAccess.phoneNumber:after>
        <span class="text-muted">
          <ul class="text-small pl-4 my-1">
            <li>Must include country code</li>
          </ul>
          <span>You will need to confirm this number before completing registration.</span>
        </span>
      </template>
      <template #accountAccess.email:after>
        <span class="text-muted">
          <span>You will need to confirm this email before completing registration.</span>
        </span>
      </template>
      <template #accountAccess.password:after>
        <div v-if="accountAccessValidation">
          <SecurePasswordChecker :validation="accountAccessValidation.password" />
        </div>
      </template>
    </ValidatedForm>
    <div class="buttons-holder">
      <VButton @click="submit" :loading="requestManager.anyPending" :disabled="isFormInvalid"> Continue </VButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.buttons-holder {
  margin: 2rem 0 5rem;
  padding: 0 10%;
  .btn {
    width: 100%;
  }
}

::v-deep.emir-classification-tooltip {
  .tooltip-inner {
    max-width: 400px;
    @include themedBackgroundColor($color-primary);
  }
  .arrow::before {
    @include themedPropColor('border-top-color', $color-primary);
  }
}
.current-account-heading {
  font-size: $base-font-size;
  font-weight: $font-weight-semibold;
  @include themedTextColor($color-primary);
}
</style>
