<template>
  <div class="change-contacts-form">
    <p class="text-small text-secondary mb-4">
      To change your contacts, you’ll need your current telephone and email for confirming the action.
    </p>
    <AccountPhoneNumberChange class="mb-4" />
    <AccountEmailChange class="mb-4" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import AccountPhoneNumberChange from './AccountPhoneNumberChange.vue';
import AccountEmailChange from './AccountEmailChange.vue';

@Component({
  components: {
    AccountPhoneNumberChange,
    AccountEmailChange,
  },
})
export default class ChangeContacts extends Vue {}
</script>
