import { render, staticRenderFns } from "./StatusBadge.vue?vue&type=template&id=377b1ea6&scoped=true"
import script from "./StatusBadge.vue?vue&type=script&lang=ts"
export * from "./StatusBadge.vue?vue&type=script&lang=ts"
import style0 from "./StatusBadge.vue?vue&type=style&index=0&id=377b1ea6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "377b1ea6",
  null
  
)

export default component.exports