import { reactive, provide, inject, onBeforeUnmount } from 'vue';
import { RequestManager, RequestState } from 'ah-requests';
import {
  RequestManagerConfig,
  WithManagerData,
  REQUEST_MANAGER_REGISTER_KEY,
  REQUEST_MANAGER_DEREGISTER_KEY,
  RequestFilter,
  RequestData,
  requestManagerConfigDefaults,
} from './models';

/**
 * Composition version of WithRequestManager mixin
 *
 * Can be used in a composition setting, and is compatible with WithRequestManager
 * (they can be used interchangeably and will interoperate)
 *
 * Please note there should ONLY BE ONE requestManager per component (as they use `provide` to manage hierarchy, and would lead to conflicts)
 */
export function useRequestManager(config?: Partial<RequestManagerConfig>) {
  const requestManager: RequestManager = reactive(new RequestManager()) as RequestManager;

  const requestManagerConfig: RequestManagerConfig = {
    ...requestManagerConfigDefaults,
    ...config,
  };

  const managerData: WithManagerData = reactive({
    manager: requestManager,
    children: [] as WithManagerData[],
    anyRequestInState: (state: RequestState, checkChildren = true) => {
      const exposeToParent = requestManagerConfig.exposeToParent;
      if (exposeToParent === true) {
        return anyRequestInState(state, checkChildren);
      } else if (Array.isArray(exposeToParent)) {
        return !!exposeToParent.find((k) => requestManager.requestStates[k] === state);
      } else if (typeof exposeToParent === 'function') {
        return !!Object.keys(requestManager.requestStates).find(
          (k) => requestManager.requestStates[k] === state && exposeToParent(k)
        );
      }
      return false;
    },
    getAllRequests: getExposedRequests,
    getAllRequestsInState: (states: RequestState | RequestState[], checkChildren = true) => {
      return getExposedRequests((key, state) => states.includes(state), checkChildren);
    },
    retryRequest: onRetryRequest,
  }) as WithManagerData;

  provide(REQUEST_MANAGER_REGISTER_KEY, (data: WithManagerData) => {
    const index = managerData.children.findIndex((m) => m.manager === data.manager);
    if (index === -1) {
      managerData.children.push(data);
    } else {
      managerData.children.splice(index, 1, data);
    }
  });

  const register = inject<(data: WithManagerData) => void>(REQUEST_MANAGER_REGISTER_KEY, () => {});

  provide(REQUEST_MANAGER_DEREGISTER_KEY, (manager: RequestManager) => {
    const index = managerData.children.findIndex((m) => m.manager === manager);
    if (index > -1) {
      managerData.children.splice(index, 1);
    }
  });

  const deregister = inject<(manager: RequestManager) => void>(REQUEST_MANAGER_DEREGISTER_KEY, () => {});

  function onRetryRequest(key: string) {
    if (requestManagerConfig.onRetryFromParentManager) {
      requestManagerConfig.onRetryFromParentManager(key);
    }
  }

  onBeforeUnmount(() => {
    deregister(requestManager);
    if (requestManagerConfig.clearOnDestroy) {
      requestManager.clear();
    }
  });

  /**
   * Check for a state in self and any children. If `checkChildren` is true, will drill down the hierarchy
   */
  function anyRequestInState(state: RequestState, checkChildren = true): boolean {
    return (
      requestManager.isAnyInState(state) ||
      (checkChildren && !!managerData.children.find((m) => m.anyRequestInState(state, checkChildren)))
    );
  }

  /**
   * Get a list of requests to expose to the parent
   */
  function getExposedRequests(filter: RequestFilter, checkChildren = true) {
    const exposeToParent = requestManagerConfig.exposeToParent;
    if (exposeToParent) {
      return getAllRequests(filter, checkChildren && exposeToParent === true).filter((r) => {
        if (Array.isArray(exposeToParent)) {
          return exposeToParent.includes(r.request);
        } else if (typeof exposeToParent === 'function') {
          return exposeToParent(r.request);
        }
        return true;
      });
    }
    return [];
  }

  /**
   * Get a list of Managers and requests in a particular state
   */
  function getAllRequestsInState(states: RequestState | RequestState[], checkChildren = true): RequestData[] {
    return getAllRequests((key, state) => states.includes(state), checkChildren);
  }

  /**
   * Get a list of Managers and requests, optionally filtered
   */
  function getAllRequests(filter: RequestFilter, checkChildren = true): RequestData[] {
    const out = getManagerRequests(filter);

    if (checkChildren) {
      out.push(...managerData.children.flatMap((m) => m.getAllRequests(filter, checkChildren)));
    }

    return out;
  }

  function getManagerRequests(filter: (key: string, state: RequestState) => boolean = () => true): RequestData[] {
    const out: RequestData[] = [];
    for (const request in requestManager.requestStates) {
      if (filter(request, requestManager.requestStates[request])) {
        out.push({ request, state: requestManager.requestStates[request], managerData: managerData });
      }
    }
    return out;
  }

  if (requestManagerConfig.exposeToParent) {
    register(managerData);
  }

  return {
    manager: requestManager,
    getAllRequests,
    getAllRequestsInState,
    retryRequest: onRetryRequest,
  };
}
