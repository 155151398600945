<template>
  <VueSelect
    class="field-group-field-input, tags-filter-select"
    :value="value"
    multiple
    :clearSearchOnBlur="clearSearchOnBlur"
    @option-selected="onOptionSelected"
    @search:focus="active = true"
    @search:blur="active = false"
    :getOptionKey="getOptionKey"
    :options="options"
    :loading="loading"
    :closeOnSelect="false"
    :disabled="isReadonly"
    :appendToBody="appendToBody"
    :calculatePosition="withPopper"
    v-on="$listeners"
  >
    <template #option="option">
      <div
        :class="{ 'custom-checkbox': useSwitches === false, 'custom-switch': useSwitches !== false }"
        class="checkbox-holder custom-control custom-checkbox"
      >
        <input
          :id="`tags-filter-select-${option.label}`"
          :checked="isOptionSelected(option)"
          type="checkbox"
          class="custom-control-input"
        />
        <label :for="`tags-filter-select-${option.label}`" class="custom-control-label">
          <span class="label-text">
            {{ option.label }}
          </span>
        </label>
      </div>
    </template>
    <template #search="{ attributes, events }">
      <input :class="`vs__search ${placeholderClass}`" v-bind="attributes" v-on="events" :placeholder="itemsText" />
    </template>
  </VueSelect>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import VueSelect from 'vue-select';
import cloneDeep from 'lodash/cloneDeep';
import { createPopper } from '@popperjs/core';

// We need to replace the VueSelect.select method with one we can hook into
// The original select method is disregardable, as we set the value ourselves
const VueSelectWithSelectEvent = Vue.extend({
  extends: VueSelect,
  methods: {
    select(option: any) {
      this.$emit('option-selected', option);
    },
  },
});

@Component({
  components: { VueSelect: VueSelectWithSelectEvent },
})
export default class TagMultiSelect extends Vue {
  @Prop({ default: false }) useSwitches!: boolean | string;

  @Prop({ default: () => [] }) value!: any[];

  @Prop({ default: () => [] }) options!: { value: string; label: string }[];

  @Prop({ default: 3 }) maxFulltextLabels!: number;

  @Prop({ default: 'items' }) itemsCountLabel!: string;

  @Prop({ default: 'All' }) emptyPlaceholder!: string;

  @Prop({ default: true }) useLabelInListing!: boolean;

  @Prop({ default: false }) readonly!: boolean | string;

  @Prop({ default: false }) loading!: boolean | string;

  @Prop({ default: false }) appendToBody!: boolean | string;

  private active = false;

  getOptionKey(option: any) {
    return option.value || option;
  }

  clearSearchOnBlur() {
    return true;
  }

  onOptionSelected(option: any) {
    const index = this.optionIndex(option);
    const value = [...this.value];
    if (index > -1) {
      value.splice(index, 1);
    } else {
      value.push(cloneDeep(option.value));
    }
    this.$emit('update:value', value);
  }

  isOptionSelected(option: any) {
    return this.optionIndex(option) > -1;
  }

  optionIndex(option: any) {
    return this.value.findIndex((i) => i === option.value);
  }

  isLastOption(option: any) {
    return this.optionIndex(option) === this.value.length - 1;
  }

  get isReadonly() {
    return this.readonly !== false;
  }

  get searchText() {
    return `Search for ${this.itemsCountLabel}...`;
  }

  get itemsText() {
    if (this.active) {
      return this.searchText;
    }
    if (this.value.length === 0) {
      return this.emptyPlaceholder;
    }
    if (this.value.length <= this.maxFulltextLabels) {
      return this.value
        .map((i) => {
          const option = this.options.find((o) => o.value === i);
          return option ? (this.useLabelInListing ? option.label : option.value) : i;
        })
        .join(', ');
    }
    return `${this.value.length} ${this.itemsCountLabel}`;
  }

  get placeholderClass() {
    return [this.emptyPlaceholder, this.searchText].includes(this.itemsText) ? '' : 'opacity-full';
  }

  withPopper(dropdownList: HTMLElement, component: Vue) {
    /**
     * We need to explicitly define the dropdown width since
     * it is usually inherited from the parent with CSS.
     */
    dropdownList.style.width = `${(component.$el as HTMLElement).getBoundingClientRect().width}px`;
    const popper = createPopper(component.$refs.toggle as any, dropdownList, {
      placement: 'bottom',
      modifiers: [
        {
          name: 'positioningClass',
          enabled: true,
          phase: 'write',
          fn({ state }) {
            component.$el.classList.toggle('drop-up', state.placement === 'top');
            dropdownList.classList.toggle('drop-up', state.placement === 'top');
            dropdownList.classList.toggle('drop-down', state.placement !== 'top');
          },
        },
      ],
    });

    return () => popper.destroy();
  }
}
</script>

<style lang="scss" scoped>
.tags-filter-select {
  .checkbox-holder {
    pointer-events: none;
  }

  ::v-deep .vs__selected {
    display: none !important;
  }

  .custom-control-label {
    padding-top: 0.25em;
    max-width: 100%;

    .label-text {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }

  .simple-tag {
    font-size: $font-size-base;
  }
}
input.vs__search.opacity-full::placeholder {
  opacity: 1;
}
</style>
