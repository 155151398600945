<template>
  <div @touchstart="touchStart" @touchend="touchEnd" class="swipable-box">
    <VRow alignH="center">
      <VCol :cols="$mediaQuery.is('smDown') ? '3' : '1'" class="text-right">
        <span v-if="canSwipeLeft" @click="swipeLeft"><IconChevronLeft class="swipe-icon left" /></span>
      </VCol>
      <VCol :cols="$mediaQuery.is('smDown') ? '6' : '9'" class="text-center">
        <Transition name="slide-and-fade" mode="out-in" appear>
          <div :key="index">
            <slot name="content" />
          </div>
        </Transition>
      </VCol>
      <VCol :cols="$mediaQuery.is('smDown') ? '3' : '2'" :class="$mediaQuery.is('smDown') ? 'text-left' : 'text-right'">
        <span v-if="canSwipeRight" @click="swipeRight"><IconChevronRight class="swipe-icon right" /></span>
      </VCol>
    </VRow>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SwipeableBox extends Vue {
  @Prop({ default: 0 }) index!: number;

  @Prop({ default: Infinity }) max!: number;

  @Prop({ default: false }) showArrows!: boolean | string;

  @Prop({ default: 1 }) step!: number;

  private touchstartX: number = 0;
  private touchstartY: number = 0;

  private touchendX: number = 0;
  private touchendY: number = 0;

  get canSwipeLeft() {
    return this.drawArrows && this.index > 0;
  }

  get canSwipeRight() {
    return this.drawArrows && this.index < this.max;
  }

  get drawArrows() {
    return this.showArrows !== false;
  }

  private swipeRight() {
    this.$emit('update:index', this.index + this.step);
    this.$emit('swipe-right');
  }

  private swipeLeft() {
    this.$emit('update:index', this.index - this.step);
    this.$emit('swipe-left');
  }

  private touchStart(event: TouchEvent) {
    this.touchstartX = event.changedTouches[0].screenX;
    this.touchstartY = event.changedTouches[0].screenY;
  }

  private touchEnd(event: TouchEvent) {
    this.touchendX = event.changedTouches[0].screenX;
    this.touchendY = event.changedTouches[0].screenY;
    this.handleGesture();
  }

  private handleGesture() {
    if (this.touchendX < this.touchstartX) {
      if (this.index + 1 <= this.max) {
        this.$emit('update:index', this.index + this.step);
      }
      this.$emit('swipe-left');
    }

    if (this.touchendX > this.touchstartX) {
      if (this.index - 1 >= 0) {
        this.$emit('update:index', this.index - this.step);
      }
      this.$emit('swipe-right');
    }

    if (this.touchendY < this.touchstartY) {
      this.$emit('swipe-up');
    }

    if (this.touchendY > this.touchstartY) {
      this.$emit('swipe-down');
    }

    if (this.touchendY === this.touchstartY) {
      this.$emit('tap');
    }
  }
}
</script>

<style lang="scss" scoped>
.app-logo {
  position: relative;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
.swipe-icon {
  cursor: pointer;
  font-size: 16px;
  @include themedTextColor($color-dark-primary, $color-primary);
  z-index: 1;
}
</style>
