<template>
  <span class="info-component-wrapper">
    <IconInfoCircle class="info-icon" v-b-tooltip.hover="tooltipObject" :title="text" />
  </span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class InfoTooltip extends Vue {
  @Prop({ required: false, default: '' }) text!: String;

  get tooltipObject() {
    return {
      html: true,
      customClass: 'info-tooltip',
      placement: 'top',
    };
  }
}
</script>

<style lang="scss" scoped>
.info-component-wrapper {
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  z-index: 1;
  position: relative;
  .info-icon {
    // little thick to align svg correctly
    margin-bottom: 4px;
    color: getColorWithOffsetLightness($color-primary, +20%);
  }
}
</style>
