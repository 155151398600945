<template>
  <div class="inner-nav">
    <div class="nav-list">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class InnerNav extends Vue {}
</script>

<style lang="scss" scoped>
.inner-nav {
  @include themedPropColor('border-right', $color-sidebar-border, $color-dark-sidebar-border, '1px solid ');

  .nav-list {
    position: relative;
    display: grid;
    grid-template-rows: auto max-content;

    ul {
      list-style: none;
      padding: 0;

      > li {
        width: 100%;
        cursor: pointer;
      }
    }
  }

  ::v-deep ul {
    .menu-link {
      width: 100%;
      padding: 0.2em 1em;
      margin-bottom: 0.2em;
      font-size: 1.1em;
      line-height: 1.2em;
      cursor: pointer;

      &:hover:not(.router-link-active) {
        background: getColor($color-highlight-grey);
        color: getColor($color-text);

        ::v-deep svg path {
          fill: getColor($color-text);
        }
      }

      &.router-link-active {
        @include themedBackgroundColor($color-primary);
        color: white;

        ::v-deep svg path {
          fill: white;
        }
      }
    }
  }
}
</style>
