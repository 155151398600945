var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['nav-view-grid', { ['no-sidebar']: !_vm.isSidebarShown || _vm.$mediaQuery.is('smDown') }]},[_c('TopNav',{staticClass:"header"}),_c('BoxGrid',{attrs:{"alignV":"start"}},[(_vm.isSidebarShown)?[_c('div',{staticClass:"d-flex flex-column col-12"},[(_vm.isClientInOnboarding)?_c('OnboardingStatusBanner'):_c('AccountStatusBanner'),_c('ActiveMarginCallBanner'),_c('AppUpdaterBanner'),_c('FeeWarningBanner')],1)]:_vm._e(),_c('BoxGridItem',{attrs:{"align-self":"stretch","cols":"12"}},[_c('RouterView',{attrs:{"name":"banner"}})],1)],2),(_vm.isSidebarShown)?_c('SideNav',{staticClass:"sidebar"}):_vm._e(),_c('div',{staticClass:"main-area"},[_c('div',{class:[
        'main-area-inner',
        {
          fullscreen: _vm.isFullscreenView,
          'with-sidebar': _vm.isSidebarShown,
          'with-sidebar-menu': _vm.isSidebarShown && _vm.hasSidebarView,
        },
      ]},[_c('RouterView')],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }