<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { toDataModel, setState, getChildModel, updateModel, setApiErrorMessages } from 'ah-common-lib/src/form/helpers';
import {
  AboutUsReference,
  aboutUsReferenceOptions,
  BaseCompanyUserRegistrationRequest,
  ClientType,
  CompanyRegistrationModel,
  UserCreationRequest,
} from 'ah-api-gateways';
import { SecurePasswordChecker } from 'ah-common-lib/src/form/components';
import { FormEvent, FormValidation } from 'ah-common-lib/src/form/interfaces';
import { accountAccessFM } from '@/app/helpers/registration/forms';
import { submitUser } from '@/app/helpers/registration/requests';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { HttpError, PayloadErrorData } from 'ah-requests';
import { constructPayloadErrors } from 'ah-requests/helpers/apiErrors';

const props = defineProps<{
  model: Partial<CompanyRegistrationModel>;
}>();

const emit = defineEmits({
  'update:model': (_value: Partial<CompanyRegistrationModel>) => true,
  proceed: () => true,
});

const apiErrors = ref<PayloadErrorData<UserCreationRequest>>();

const { manager: requestManager } = useRequestManager();

const accountAccessForm = ref(accountAccessFM({ isCompanyApplicant: true }));
const accountAccessValidation = ref<FormValidation>();

const calculatedModel = computed((): UserCreationRequest => {
  const { referenceDescription, referenceSelection, ...applicant } = toDataModel(accountAccessForm.value);

  return {
    applicant,
    registrationData: {
      jobTitle: accountAccessForm.value.jobTitle,
      questionnaireAnswer: referenceSelection === AboutUsReference.OTHER ? referenceDescription : referenceSelection,
      incorporationCountry: props.model.address?.countryCode,
      referralId: props.model.referralId,
    } as BaseCompanyUserRegistrationRequest,
  };
});

const validation = computed(() => ({
  $model: calculatedModel.value,
  $invalid: !!accountAccessValidation.value?.$invalid,
  $dirty: !!accountAccessValidation.value?.$dirty,
}));

const onFormEvent = (formEvent: FormEvent) => {
  if (formEvent.event === 'form-field-set-value') {
    setState(formEvent.model, 'errors', []);
    const { referenceDescription, referenceSelection, ...applicant } = toDataModel(accountAccessForm.value);
    if (formEvent.model.$name === 'referenceSelection') {
      checkReferenceDescriptionDisplay();
      accountAccessForm.value.referenceDescription = '';
      if (accountAccessValidation.value?.referenceDescription) {
        accountAccessValidation.value.referenceDescription.$reset();
      }
    }
    emit('update:model', {
      ...props.model,
      applicant: { ...props.model.applicant, ...applicant },
      reference: referenceSelection === AboutUsReference.OTHER ? referenceDescription : referenceSelection,
    });
  }
};

const submit = () => {
  apiErrors.value = undefined;

  requestManager
    .sameOrCancelAndNew(
      'registerUser',
      submitUser(props.model.clientType ?? ClientType.COMPANY, calculatedModel.value, accountAccessForm.value)
    )
    .subscribe(
      () => emit('proceed'),
      (error: HttpError) => {
        apiErrors.value = constructPayloadErrors<UserCreationRequest>(error.response!.data);

        if (apiErrors.value.fields?.applicant) {
          setApiErrorMessages(apiErrors.value.fields?.applicant, accountAccessForm.value);
        }
      }
    );
};

const checkReferenceDescriptionDisplay = () => {
  const field = getChildModel(accountAccessForm.value, 'referenceDescription')!;
  setState(field, 'hidden', accountAccessForm.value.referenceSelection !== AboutUsReference.OTHER);
  setState(field, 'required', accountAccessForm.value.referenceSelection === AboutUsReference.OTHER);
};

watch(
  () => props.model,
  () => {
    updateModel(accountAccessForm.value, { ...props.model?.applicant });

    if (typeof props.model?.reference === 'string') {
      if (
        aboutUsReferenceOptions
          .map((obj) => obj.value)
          .filter((v) => v !== AboutUsReference.OTHER)
          .includes(props.model.reference)
      ) {
        accountAccessForm.value.referenceSelection = props.model.reference;
      } else {
        accountAccessForm.value.referenceSelection = AboutUsReference.OTHER;
        accountAccessForm.value.referenceDescription = props.model.reference;
      }
      checkReferenceDescriptionDisplay();
    }
  },
  { immediate: true }
);
</script>

<template>
  <div x-test-name="representative-form">
    <h2>Contact Details</h2>
    <ValidatedForm :fm="accountAccessForm" :validation.sync="accountAccessValidation" @form-event="onFormEvent">
      <template #accountAccess.phoneNumber:after>
        <span class="text-muted">
          <ul class="text-small pl-4 my-1">
            <li>Must include country code</li>
          </ul>
          <span>You will need to confirm this number before completing registration.</span>
        </span>
      </template>
      <template #accountAccess.email:after>
        <span class="text-muted">
          <span>You will need to confirm this email before completing registration.</span>
        </span>
      </template>
      <template #accountAccess.password:after>
        <div v-if="accountAccessValidation">
          <SecurePasswordChecker :validation="accountAccessValidation.password" />
        </div>
      </template>
    </ValidatedForm>
    <div class="buttons-holder">
      <VButton @click="submit" :loading="requestManager.anyPending" :disabled="validation && validation.$invalid">
        Continue
      </VButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.buttons-holder {
  margin: 2rem 0 5rem;
  padding: 0 10%;
  .btn {
    width: 100%;
  }
}
</style>
