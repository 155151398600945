<script setup lang="ts">
import TradeDefinitionFormGroup from './TradeDefinitionFormGroup.vue';
import TradeDestinationForm from './TradeDestinationForm.vue';
import CostTransparencyDetailsInfo from '../info/CostTransparencyDetailsInfo.vue';
import { TradeDestination } from './tradeDestination';
import { TradeFundsDestination, TradeDetails } from '../../models/trade';
import {
  Beneficiary,
  BankingScheme,
  FeeChargeType,
  formatHedgingInstrument,
  FeePaymentType,
  AmountType,
  FeatureFlag,
  FeatureFlagTreatment,
  ComplianceStatus,
} from 'ah-api-gateways';
import { QuotePriceRequest } from '../../requests/pricingRequest';
import CurrencyFundingFee from '../fees/CurrencyFundingFee.vue';
import SchedulePaymentForm from 'ah-wallets/src/components/SchedulePaymentForm.vue';
import ChargeTypeForm from 'ah-wallets/src/components/ChargeTypeForm.vue';
import PaymentReferenceForm from './PaymentReferenceForm.vue';
import PaymentReasonForm from 'ah-wallets/src/components/PaymentReasonForm.vue';
import { computed, reactive, ref, watch } from 'vue';
import { useAhTradesState } from '../..';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { useFeatureFlag } from 'ah-common-stores';

const emit = defineEmits<{
  (e: 'update:tradePrice', value: Partial<QuotePriceRequest>): void;
  (e: 'update:tradeDetails', value: Partial<TradeDetails>): void;
  (e: 'update:tradeDestination', value: Partial<TradeDestination>): void;
  (e: 'update:costTransparencyCurrency', value: AmountType): void;
  (e: 'update:walletBalanceUsageRequired', value: boolean): void;
  (e: 'trade-submitted'): void;
  (e: 'trade-cancelled'): void;
}>();

const tradeDestinationForm = ref<InstanceType<typeof TradeDestinationForm> | null>(null);

const tradeDefinitionFormGroup = ref<InstanceType<typeof TradeDefinitionFormGroup> | null>(null);

const schedulePaymentForm = ref<InstanceType<typeof SchedulePaymentForm> | null>(null);

const chargeTypeForm = ref<InstanceType<typeof ChargeTypeForm> | null>(null);

const paymentReferenceForm = ref<InstanceType<typeof PaymentReferenceForm> | null>(null);

const paymentReasonForm = ref<InstanceType<typeof PaymentReasonForm> | null>(null);

const props = withDefaults(
  defineProps<{
    /**
     * Trade details object (managed by TradeDetailsForm)
     *
     * synchronizable via `update:tradeDetails` or .sync modifier
     */
    tradeDetails?: TradeDetails | null;

    /**
     * Trade price request and response (as received by getting/refreshing  the trade)
     *
     * synchronizable via `update:tradePrice` or .sync modifier
     */
    tradePrice?: QuotePriceRequest | null;
    /**
     * Trade destination object (managed by TradeDestinationForm)
     *
     * synchronizable via `update:tradeDestination` or .sync modifier
     */
    tradeDestination?: TradeDestination | null;
    /**
     * Whether the UI is narrow (i.e. displayed in a single column)
     *
     * Used when in a modal context
     *
     * A value-less prop (empty string) will be considered as a truthy value
     * (<Component foo /> => <Component :foo=true />)
     */
    narrow?: boolean | string;
    /**
     * Whether to show the cancel button
     *
     * A value-less prop (empty string) will be considered as a truthy value
     * (<Component foo /> => <Component :foo=true />)
     */
    showCancel?: boolean | string;
    /**
     * Possible beneficiary target of the trade
     * Will affect UX and options available in child components
     *
     * synchronizable via `update:beneficiary` or .sync modifier
     */
    beneficiary?: Beneficiary;
    /**
     * Whether to force keeping funds
     *
     * If set, user cannot choose the target of the trade, and will always keep them
     * (i.e. move to another wallet)
     *
     * A value-less prop (empty string) will be considered as a truthy value
     * (<Component foo /> => <Component :foo=true />)
     *
     */
    forceKeep?: boolean | string;
    /**
     * Whether to force showing destination form
     *
     * If set, destination form will not be hidden, even if forceKeep is true
     *
     * A value-less prop (empty string) will be considered as a truthy value
     * (<Component foo /> => <Component :foo=true />)
     */
    forceShowDestination?: boolean | string;
    /**
     * Whether to force a spot.
     *
     * If set, user cannot choose the type of trade
     *
     * A value-less prop (empty string) will be considered as a truthy value
     * (<Component foo /> => <Component :foo=true />)
     */
    forceImmediateSpot?: boolean | string;
    /**
     * Possible array of allowed buy currencies
     *
     * If set, user will only be able to choose from the currencies listed as a buy currency
     */
    allowedBuyCurrencies?: string[];
    /**
     * Possible array of allowed sell currencies
     *
     * If set, user will only be able to choose from the currencies listed as a sell currency
     */
    allowedSellCurrencies?: string[];
    /**
     * Whether to sync trade funds.
     *
     * If set, trades will be synchronized, and user will see an alert to that effect
     *
     * A value-less prop (empty string) will be considered as a truthy value
     * (<Component foo /> => <Component :foo=true />)
     */
    syncTradeFunds?: boolean | string;
    /**
     * Whether the current trade is supporting a direct payment.
     *
     * If true:
     * - beneficiaries list will not be displayed
     * - `beneficiary` prop is expected to be non-null
     * - trade date will not affect payment date, and will be set independently
     */
    isPayment?: string | boolean;
    /**
     * Whether to allow keeping funds
     */
    allowKeepFunds?: string | boolean;
    /**
     * Whether to verify limits
     *
     * If true:
     * - client will verify limits
     */
    verifyLimits?: string | boolean;
    /**
     * Whether to show cost transparency
     *
     * If true:
     * - client will show cost transparency
     */
    costTransparency?: string | boolean;
    costTransparencyCurrency: AmountType;
  }>(),
  {
    tradeDetails: null,
    tradePrice: null,
    tradeDestination: null,
    narrow: false,
    showCancel: false,
    forceKeep: false,
    forceImmediateSpot: false,
    syncTradeFunds: false,
    verifyLimits: false,
    costTransparency: false,
    isPayment: false,
    allowKeepFunds: true,
  }
);

const state = reactive({
  loadingPrices: false as boolean,
  submittingTrade: false as boolean,
});

const requestManager = useRequestManager().manager;

const tradeState = useAhTradesState();

const onBehalfOfClient = useOnBehalfOf();

const authStore = tradeState.store.useAuthStore();

const walletStore = tradeState.store.useWalletsStore();

const displayCostTransparency = ref<boolean>(false);

const isActiveMarginCall = computed(() => {
  if (clientId.value) {
    return walletStore.getClientRiskProfile(clientId.value)?.collateralProfile?.activeMarginCall;
  }
  return null;
});

const clientId = computed(() => onBehalfOfClient.value?.id ?? authStore.loggedInIdentity?.client?.id);

const showCostTransparency = computed(() => props.costTransparency !== false);

/**
 * We can assume authStore.complianceStatus is set, as, if null,
 * it means we are in a OBO scenario (active user is a Partner or an Admin)
 */
const isNotPermittedToTrade = computed(
  () =>
    [
      ComplianceStatus.SUSPENDED,
      ComplianceStatus.DEACTIVATED,
      ComplianceStatus.SUSPENDED_UPDATED_TCS_NOT_ACCEPTED,
    ].includes(authStore.complianceStatus as any) || isActiveMarginCall.value
);

const { loadFlag: loadVariationMarginFeatureFlag } = useFeatureFlag({
  featureFlag: FeatureFlag.VARIATION_MARGIN_AND_LIMITS_FEATURE,
});

function loadCostDisplayFeatureFlag() {
  const payload = {
    featureFlag: FeatureFlag.PRICING_SHOW_CLIENT_COSTS,
    oboClientId: onBehalfOfClient.value?.id,
    force: true,
  };

  if (showCostTransparency.value) {
    tradeState.store
      .useFeatureFlagStore()
      .loadFeatureFlag(payload)
      .then(
        (featureFlagResponse) =>
          (displayCostTransparency.value = featureFlagResponse?.treatment === FeatureFlagTreatment.ON)
      );
  }
}

// We skip feature flags checking in x-office to hide cost transparency in trading obo
if (!tradeState.store.useAuthStore().isAHUser) {
  loadCostDisplayFeatureFlag();
}

const isNarrow = computed(() => props.narrow !== false);

const isForceKeep = computed(() => props.forceKeep !== false);

const isForceShowDestination = computed(() => props.forceShowDestination !== false);

const isCancelShown = computed(() => props.showCancel !== false);

const isTrade = computed(
  () => !props.tradeDetails || props.tradeDetails.sellCurrency !== props.tradeDetails.buyCurrency
);

const invalidForms = computed(() => !props.tradePrice || !props.tradeDestination?.valid);

const isSendingMoney = computed(() => props.isPayment !== false);

const tradeProduct = computed(() => {
  if (!props.tradePrice) {
    return 'Trade';
  }
  return formatHedgingInstrument(props.tradePrice.instrument);
});

const targetBeneficiary = computed(() => props.tradeDestination?.beneficiary);

const minPossiblePaymentDate = computed(() => {
  if (props.tradePrice?.response?.settlementDate) {
    return new Date(props.tradePrice.response.settlementDate);
  }

  return undefined;
});

const useChargeType = computed(() => props.tradeDestination?.beneficiary?.bankingScheme === BankingScheme.SWIFT);

function onForceKeepChange() {
  if (isForceKeep.value) {
    emit('update:tradeDestination', {
      destination: TradeFundsDestination.KEEP,
      beneficiary: null,
      valid: true,
    });
  } else if (props.tradeDestination?.destination === TradeFundsDestination.SEND) {
    emit('update:tradeDestination', {
      ...props.tradeDestination,
    });
  }
}

function submitTrade() {
  // Set a submittingTrade state that will persist until loadingPrices is false, at which point trade submittal continues
  state.submittingTrade = true;
  if (state.loadingPrices) {
    return;
  }
  state.submittingTrade = false;

  schedulePaymentForm.value?.touchForms();
  chargeTypeForm.value?.touchForms();
  paymentReasonForm.value?.touchForms();
  paymentReferenceForm.value?.touchForms();

  if (tradeDefinitionFormGroup.value) {
    tradeDefinitionFormGroup.value.touchForms();
  }

  const scheduleDateInvalid = schedulePaymentForm.value?.valid === false;
  const chargeTypeInvalid = chargeTypeForm.value?.valid === false;
  const paymentReasonInvalid = paymentReasonForm.value?.valid === false;
  const paymentReferenceInvalid = paymentReferenceForm.value?.valid === false;
  const paymentOverLimit = tradeDestinationForm.value?.isPaymentInvalid === true;
  const definitionValid = tradeDefinitionFormGroup.value?.areDatesValid() === true;

  if (
    !props.tradeDetails?.valid ||
    (isTrade.value && !props.tradePrice) ||
    scheduleDateInvalid ||
    chargeTypeInvalid ||
    paymentReasonInvalid ||
    paymentReferenceInvalid ||
    !props.tradePrice ||
    !definitionValid ||
    paymentOverLimit
  ) {
    tradeState.toast.clear('tradeDetailsInfo');
    tradeState.toast.info('Please review trade details', {
      group: 'tradeDetailsInfo',
    });
    return;
  }

  if (useChargeType.value === true) {
    emit('update:tradeDestination', {
      ...props.tradeDestination,
    });
  } else {
    let tradeDestination = {
      ...props.tradeDestination,
    };

    delete tradeDestination.chargeType;

    emit('update:tradeDestination', {
      ...tradeDestination,
    });
  }

  if (
    !props.tradeDestination ||
    (props.tradeDestination?.destination === TradeFundsDestination.SEND && !props.tradeDestination?.beneficiary)
  ) {
    tradeState.toast.info('Please select a destination bank account');
    return;
  }

  emit('trade-submitted');
}

function onLoadingPricesChange() {
  if (state.loadingPrices === false && state.submittingTrade === true) {
    submitTrade();
  }
}

function onReasonUpdate(reason: string | null) {
  emit('update:tradeDestination', { ...props.tradeDestination, reason: reason ?? undefined });
}

function onReferenceUpdate(reference: string | null) {
  emit('update:tradeDestination', { ...props.tradeDestination, reference: reference ?? undefined });
}

function onChargeTypeUpdate(chargeType: FeeChargeType | null) {
  emit('update:tradeDestination', { ...props.tradeDestination, chargeType: chargeType ?? undefined });
}

function cancelTrade() {
  emit('trade-cancelled');
}

function loadClientRiskProfileReport() {
  if (!clientId?.value) {
    throw 'Cannot load client risk profile report with incomplete data';
  }

  const id = clientId.value;

  return requestManager.newPromise(
    'loadClientRiskProfileReport',
    loadVariationMarginFeatureFlag().then((flag) => {
      if (flag?.treatment === FeatureFlagTreatment.ON) {
        return walletStore.loadClientRiskProfile({
          force: true,
          clientId: id,
        });
      }
    })
  );
}

watch(
  () => state.loadingPrices,
  () => {
    onLoadingPricesChange();
  }
);

watch(
  () => [isForceKeep.value],
  () => {
    onForceKeepChange();
  },
  { immediate: true }
);

watch(
  clientId,
  () => {
    if (clientId.value) {
      loadClientRiskProfileReport();
    }
  },
  { immediate: true }
);
</script>

<template>
  <div>
    <!-- main blocks -->
    <BoxGrid>
      <!-- trade details -->
      <BoxGridBlock
        v-bind="!isNarrow ? { cols: 5, sm: 12, lg: 5 } : { cols: 12 }"
        :loadingOverlayProps="{ showRetry: true }"
      >
        <h3>Trade Details</h3>
        <TradeDefinitionFormGroup
          ref="tradeDefinitionFormGroup"
          @update:tradeDetails="emit('update:tradeDetails', $event)"
          :tradeDetails="tradeDetails"
          :loadingPrices.sync="state.loadingPrices"
          :beneficiary="beneficiary"
          :allowedBuyCurrencies="allowedBuyCurrencies"
          :allowedSellCurrencies="allowedSellCurrencies"
          :forceImmediateSpot="forceImmediateSpot"
          :forceKeep="isForceKeep"
          :syncTradeFunds="syncTradeFunds"
          :verifyLimits="verifyLimits"
          @update:tradePrice="emit('update:tradePrice', $event)"
          @update:walletBalanceUsageRequired="emit('update:walletBalanceUsageRequired', $event)"
          :tradePrice="tradePrice"
          :narrow="isNarrow"
        />
      </BoxGridBlock>

      <!-- keep or send funds -->
      <BoxGridItem class="mb-0" v-bind="!isNarrow ? { cols: 7, sm: 12, lg: 7 } : { cols: 12 }">
        <BoxGrid>
          <BoxGridBlock class="mb-3" v-if="(!isForceKeep || isForceShowDestination) && !isSendingMoney">
            <TradeDestinationForm
              ref="tradeDestinationForm"
              :allowKeepFunds="allowKeepFunds"
              :allowSendMoney="!isForceKeep"
              :tradePrice="tradePrice?.response"
              :tradeDetails="tradeDetails"
              :tradeDestination="tradeDestination"
              :beneficiary="beneficiary"
              @update:tradeDestination="emit('update:tradeDestination', $event)"
            />
          </BoxGridBlock>
          <template v-if="isSendingMoney">
            <BoxGridBlock class="mb-3" cols="12">
              <SchedulePaymentForm
                ref="schedulePaymentForm"
                class="mt-3 mb-n3"
                :beneficiary="beneficiary"
                :minDate="minPossiblePaymentDate"
                :tradeDestination="tradeDestination"
                :tradePrice="tradePrice?.response"
                @update:tradeDestination="emit('update:tradeDestination', $event)"
              />
            </BoxGridBlock>
          </template>
          <BoxGridBlock class="mb-3" cols="12" v-if="tradeDestination && targetBeneficiary">
            <PaymentReasonForm
              ref="paymentReasonForm"
              :reason="tradeDestination.reason"
              :narrow="isNarrow"
              :beneficiary="targetBeneficiary"
              @update:reason="onReasonUpdate"
            />
          </BoxGridBlock>
          <BoxGridBlock
            class="mb-3"
            cols="12"
            v-if="!forceKeep && tradeDestination !== null && tradeDestination.beneficiary !== null"
          >
            <PaymentReferenceForm
              ref="paymentReferenceForm"
              :narrow="isNarrow"
              :beneficiary="tradeDestination.beneficiary"
              :reference="tradeDestination.reference"
              @update:reference="onReferenceUpdate"
            />
          </BoxGridBlock>
          <BoxGridBlock class="mb-3" cols="12" v-if="tradeDestination && targetBeneficiary">
            <ChargeTypeForm
              ref="chargeTypeForm"
              :chargeType="tradeDestination.chargeType"
              @update:chargeType="onChargeTypeUpdate"
              v-if="useChargeType"
            />
            <CurrencyFundingFee
              md="3"
              :paymentCurrency="targetBeneficiary.currency"
              :bankingScheme="targetBeneficiary.bankingScheme"
              :chargeType="tradeDestination.chargeType ?? undefined"
              :beneficiary="targetBeneficiary"
              :feePaymentType="FeePaymentType.PAYMENT"
            />
          </BoxGridBlock>

          <!-- cost transparency -->
          <BoxGridBlock class="mb-3" cols="12" v-if="displayCostTransparency && tradeDetails">
            <CostTransparencyDetailsInfo
              :tradeDetails="tradeDetails"
              :tradePrice="tradePrice?.response"
              :costTransparencyCurrency="costTransparencyCurrency"
              @update:costTransparencyCurrency="emit('update:costTransparencyCurrency', $event)"
            />
          </BoxGridBlock>
        </BoxGrid>
      </BoxGridItem>
    </BoxGrid>
    <div class="my-4" :class="$ahTradesState.mediaQuery.is('smDown') ? 'text-center' : 'text-right '">
      <VButton v-if="isCancelShown" @click="cancelTrade" class="btn-secondary mr-2">Cancel</VButton>
      <VButton
        @click="submitTrade"
        :loading="state.submittingTrade || state.loadingPrices"
        :disabled="invalidForms || isNotPermittedToTrade"
      >
        <template v-if="isTrade"> Continue with {{ tradeProduct }} <IconArrowRight class="ml-3" /> </template>
        <template v-else> Send Money </template>
      </VButton>
    </div>
  </div>
</template>
