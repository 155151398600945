import { capitalize } from 'lodash';

export enum HedgingInstruments {
  FX_SPOT = 'FX_SPOT',
  FX_FORWARD = 'FX_FORWARD',
  VANILLA_OPTIONS = 'VANILLA_OPTIONS',
  WINDOW_FORWARD = 'WINDOW_FORWARD',
  PARTICIPATING_FORWARD = 'PARTICIPATING_FORWARD',
  FLEXIBLE_FORWARD = 'FLEXIBLE_FORWARD',
}

export enum HedgingInstrumentsSettingsProps {
  fxSpots = 'fxSpots',
  fxForwards = 'fxForwards',
  windowForwards = 'windowForwards',
  flexibleForwards = 'flexibleForwards',
  vanillaOptions = 'vanillaOptions',
  participatingForwards = 'participatingForwards',
}

export const forwardInstruments = [
  HedgingInstruments.FX_FORWARD,
  HedgingInstruments.WINDOW_FORWARD,
  HedgingInstruments.FLEXIBLE_FORWARD,
];

export const mifidHedgingInstrumentsSettingsProps = [
  HedgingInstrumentsSettingsProps.vanillaOptions,
  HedgingInstrumentsSettingsProps.participatingForwards,
] as const;

export const nonMifidHedgingInstrumentsSettingsProps = [
  HedgingInstrumentsSettingsProps.fxSpots,
  HedgingInstrumentsSettingsProps.fxForwards,
  HedgingInstrumentsSettingsProps.windowForwards,
  HedgingInstrumentsSettingsProps.flexibleForwards,
] as const;

export type MifidHedgingInstrumentsSettingsProps = (typeof mifidHedgingInstrumentsSettingsProps)[number];

export type NonMifidHedgingInstrumentsSettingsProps = (typeof nonMifidHedgingInstrumentsSettingsProps)[number];

export const hedgingInstrumentToHuman: Record<HedgingInstruments, string> = {
  FX_SPOT: 'FX Spot',
  FX_FORWARD: 'Fixed Forward',
  WINDOW_FORWARD: 'Window Forward',
  VANILLA_OPTIONS: 'Vanilla Option',
  PARTICIPATING_FORWARD: 'Participating Forward',
  FLEXIBLE_FORWARD: 'Flexible Forward',
};

export function formatHedgingInstrument(instrument?: string, defaultStr = '') {
  if (!instrument) {
    return defaultStr;
  }
  return hedgingInstrumentToHuman[instrument as HedgingInstruments] || defaultStr;
}

export const purposeTypeToHuman = (purposeType: string) => {
  if (!purposeType) {
    return '';
  }
  return capitalize(purposeType.replaceAll('_', ' '));
};

export const hedgingInstrumentSettingPropToHuman: Record<HedgingInstrumentsSettingsProps, string> = {
  fxSpots: 'FX Spot',
  fxForwards: 'Fixed Forward',
  windowForwards: 'Window Forward',
  vanillaOptions: 'Vanilla Option',
  participatingForwards: 'Participating Forward',
  flexibleForwards: 'Flexible Forward',
};

export const hedgingInstrumentEnumToSettingProp: {
  [key in HedgingInstruments]: HedgingInstrumentsSettingsProps;
} = {
  FX_SPOT: HedgingInstrumentsSettingsProps.fxSpots,
  FX_FORWARD: HedgingInstrumentsSettingsProps.fxForwards,
  WINDOW_FORWARD: HedgingInstrumentsSettingsProps.windowForwards,
  VANILLA_OPTIONS: HedgingInstrumentsSettingsProps.vanillaOptions,
  PARTICIPATING_FORWARD: HedgingInstrumentsSettingsProps.participatingForwards,
  FLEXIBLE_FORWARD: HedgingInstrumentsSettingsProps.flexibleForwards,
};

export const nonMifidHedgingInstrumentSettingPropToHuman: {
  [key in NonMifidHedgingInstrumentsSettingsProps]: string;
} = {
  [HedgingInstrumentsSettingsProps.fxSpots]: 'FX Spot',
  [HedgingInstrumentsSettingsProps.fxForwards]: 'Fixed Forward',
  [HedgingInstrumentsSettingsProps.windowForwards]: 'Window Forward',
  [HedgingInstrumentsSettingsProps.flexibleForwards]: 'Flexible Forward',
};

export const fxHedgingInstruments = [
  HedgingInstruments.FX_SPOT,
  HedgingInstruments.FX_FORWARD,
  HedgingInstruments.WINDOW_FORWARD,
  HedgingInstruments.FLEXIBLE_FORWARD,
];

export const mifidHedgingInstrumentSettingPropToHuman: Record<MifidHedgingInstrumentsSettingsProps, string> = {
  [HedgingInstrumentsSettingsProps.vanillaOptions]: 'Vanilla Option',
  [HedgingInstrumentsSettingsProps.participatingForwards]: 'Participating Forward',
};
