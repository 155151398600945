<script setup lang="ts">
import OwnerReview from '@/app/components/onboarding/OwnerReview.vue';
import EmployeeReview from '@/app/components/onboarding/EmployeeReview.vue';
import { useAuthStore } from '@/app/store/authStore';
import { computed, onBeforeMount, ref } from 'vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { Client } from 'ah-api-gateways';

const authStore = useAuthStore();

const requestManager = useRequestManager().manager;

const services = getServices();

const client = ref<Client>();

const isOwner = computed(() => authStore.loggedInIdentity?.owner);

function loadClient() {
  requestManager
    .sameOrCancelAndNew('loadClient', services.client.getClient(authStore.loggedInIdentity!.client!.id))
    .subscribe((res) => (client.value = res));
}

function sendToLogin() {
  authStore.logout({
    redirect: 'first-login',
    message: 'Registration completed successfully. Log in to proceed',
  });
}

onBeforeMount(loadClient);
</script>

<template>
  <SimpleCenteredPage fullHeight>
    <LoadingOverlay :state="requestManager.requestStates" show-retry @retry="loadClient">
      <div class="mx-md-4 mx-sm-0" v-if="client">
        <OwnerReview :client.sync="client" @flow-finished="sendToLogin" v-if="isOwner" id="owner-review-view" />
        <EmployeeReview :client="client" @flow-finished="sendToLogin" v-else id="employee-review-view" />
      </div>
    </LoadingOverlay>
  </SimpleCenteredPage>
</template>
