<script setup lang="ts">
import { computed, onBeforeMount, reactive, ref } from 'vue';
import { useRouter } from 'vue-router/composables';
import RegistrationTypeSelection from '@/app/components/registration/RegistrationTypeSelection.vue';
import PersonalRegistration from '@/app/components/registration/PersonalRegistration.vue';
import CompanyRegistration from '@/app/components/registration/CompanyRegistration.vue';
import { useAuthStore } from '@/app/store/authStore';
import { ClientType } from 'ah-api-gateways';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { handleLoadRegistrationError } from '@/app/helpers/registration/requests';
import RegistrationLanding from '@/app/components/registration/RegistrationLanding.vue';

type RegistrationStage = 'landing' | 'typeSelection' | 'companyRegistration' | 'personalRegistration';
type RegistrationType = '' | 'company' | 'personal';

const props = defineProps({
  landingSource: {
    type: String,
  },
  source: {
    type: String,
  },
  clientId: {
    type: String,
  },
  individualId: {
    type: String,
  },
});

const authStore = useAuthStore();

const router = useRouter();

const requestManager = useRequestManager().manager;

const services = getServices();

const scrollHolder = ref<Element>();

const landingReferral = computed(() => router.currentRoute.query?.refer as string);

const state = reactive({
  currentStage: null as RegistrationStage | null,
  selected: '' as RegistrationType,
});

const prefilledEmail = ref('');
const referralId = ref('');

function onRegistered() {
  authStore.logout({
    redirect: 'first-login',
    message: 'Registration completed successfully. Log in to proceed',
  });
}

function onLandingEmailSubmitted(submittedEmail: string, submittedReferralId: string) {
  prefilledEmail.value = submittedEmail;
  referralId.value = submittedReferralId;
  // `/register` is the route using this View component without a landing prop set
  // we redirect to it but the component will always be kept
  if (router.currentRoute.path !== '/register') {
    router.replace('/register');
  }
  state.currentStage = 'typeSelection';
}

function onRegistrationTypeSelected(type: RegistrationType) {
  state.selected = type;
  state.currentStage = type ? (`${type}Registration` as RegistrationStage) : 'typeSelection';
}

function onPageChanged() {
  if (scrollHolder.value) {
    scrollHolder.value.scrollTop = 0;
  }
}

function setupRegistrationFlow() {
  new Promise((r) => {
    if (authStore.hasOTPSession && !authStore.hasRegistrationSession) {
      r(authStore.logout({ redirect: '' }));
    } else {
      r(null);
    }
  }).then(() => {
    if (authStore.loggedInIdentity?.client?.type) {
      state.currentStage =
        authStore.loggedInIdentity.client.type === ClientType.COMPANY ? 'companyRegistration' : 'personalRegistration';
    } else if (authStore.userData) {
      requestManager
        .sameOrCancelAndNew('loadRegistrationData', services.registration.getRegistrationUser(authStore.userData.id))
        .subscribe((res) => {
          if (res.clientType === ClientType.INDIVIDUAL) {
            state.currentStage = 'personalRegistration';
          } else {
            state.currentStage = 'companyRegistration';
          }
        }, handleLoadRegistrationError);
    } else {
      state.currentStage = !!props.landingSource || !!props.source ? 'landing' : 'typeSelection';
    }
  });
}

onBeforeMount(setupRegistrationFlow);
</script>

<template>
  <LoadingOverlay :state="requestManager.requestStates" show-retry @retry="setupRegistrationFlow">
    <div class="holder" ref="scrollHolder">
      <RegistrationLanding
        v-if="state.currentStage === 'landing'"
        :source="landingSource || source"
        :referral="landingReferral"
        :clientId="clientId"
        :individualId="individualId"
        id="register-view-landing"
        @email-submitted="onLandingEmailSubmitted"
      />
      <RegistrationTypeSelection
        v-if="state.currentStage === 'typeSelection'"
        @update:selected="onRegistrationTypeSelected"
        id="register-view-type-selection"
        :selected="state.selected"
      />
      <CompanyRegistration
        @registered="onRegistered"
        :email="prefilledEmail"
        :referral-id="referralId"
        v-else-if="state.currentStage === 'companyRegistration'"
        class="register-view"
        id="register-view-company"
        @page-changed="onPageChanged"
        @back-to-type="state.currentStage = 'typeSelection'"
      />
      <PersonalRegistration
        v-else-if="state.currentStage === 'personalRegistration'"
        @registered="onRegistered"
        :email="prefilledEmail"
        :referral-id="referralId"
        class="register-view"
        id="register-view-personal"
        @page-changed="onPageChanged"
        @back-to-type="state.currentStage = 'typeSelection'"
      />
    </div>
  </LoadingOverlay>
</template>

<style lang="scss" scoped>
.main-area.padded {
  padding-top: 2 * $padded-space;
  padding-bottom: 2 * $padded-space;

  @include desktop {
    padding-top: 4 * $padded-space;
    padding-bottom: 4 * $padded-space;
  }
}
.register-view {
  height: 100%;
}

.holder {
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>
