<script setup lang="ts">
import { AuthorityType, ClientRiskProfileReport, PaginatedParams } from 'ah-api-gateways';
import { VCol } from 'ah-common-lib/src/common/components';
import { VRow } from 'ah-common-lib/src/common/components';
import LoadingOverlay from 'ah-common-lib/src/common/components/overlays/LoadingOverlay.vue';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { computed, watch, ref } from 'vue';
import YourMTMPosition from './YourMTMPosition.vue';
import OpenPositionsMtmListing from './OpenPositionsMtmListing.vue';
import NoAvailableMarkToMarket from './NoAvailableMarkToMarket.vue';
import CreditLimitsOnForwardsAndOptions from './CreditLimitsOnForwardsAndOptions.vue';
import { ErrorExclamationIcon } from 'ah-common-lib/src/icons/components';
import { WarningExclamationIcon } from 'ah-common-lib/src/icons/components';
import { useOnBehalfOf } from 'ah-common-lib/src/onBehalfOf/useInjectedOBO';
import { useAhTradesState } from '../..';

const props = defineProps({
  /**
   * Client ID to display credit and mark-to-market for
   */
  clientId: {
    type: String,
  },
});

const onBehalfOfClient = useOnBehalfOf();

const requestManager = useRequestManager().manager;

const tradeState = useAhTradesState();

const ownerId = computed(
  () => props.clientId ?? onBehalfOfClient.value?.id ?? tradeState.store.useAuthStore().loggedInIdentity?.client?.id
);

const canSeeTrades = computed(() => tradeState.store.useAuthStore().hasAuthorities(AuthorityType.VIEW_TRADES));

const clientRiskProfileReportData = computed<ClientRiskProfileReport | null>(() =>
  ownerId.value ? tradeState.store.useWalletsStore().getClientRiskProfile(ownerId.value) : null
);

const isMTMNegative = computed(
  () => clientRiskProfileReportData.value && clientRiskProfileReportData.value.collateralProfile.totalMarkToMarket < 0
);

const isMTMGreaterThanVMLimit = computed(
  () =>
    clientRiskProfileReportData.value &&
    Math.abs(clientRiskProfileReportData.value.collateralProfile.totalMarkToMarket) >
      clientRiskProfileReportData.value.collateralProfile.variationMarginAmount
);

const isVMLimitAndMTMZeroAmount = computed(
  () =>
    clientRiskProfileReportData.value?.collateralProfile.totalMarkToMarket === 0 &&
    clientRiskProfileReportData.value.collateralProfile.variationMarginAmount === 0
);

const defaultSortAndPageParams: Partial<PaginatedParams> = {
  sort: 'gbpPositionMtm',
  sortDirection: 'ASC',
};

function loadClientRiskProfileReport() {
  // FeatureFlag.VARIATION_MARGIN_AND_LIMITS_FEATURE is assumed to be active, as this component only displays if it is
  if (!ownerId?.value) {
    throw 'Cannot load client risk profile report with incomplete data';
  }

  // Load happens with force: true as this component sits at the root of a view
  return requestManager.newPromise(
    'loadClientRiskProfileReport',
    tradeState.store.useWalletsStore().loadClientRiskProfile({ force: true, clientId: ownerId.value })
  );
}

watch(ownerId, () => ownerId.value && loadClientRiskProfileReport(), { immediate: true });

/**
 * This will guarantee that the Your Mark-to-Market Position's component always has
 * the same height as Credit Limits on Forward & Options' component since it will 'inherit' is height
 */
const creditLimitsOnForwardsAndOptionsHeight = ref<number>();

const getComponentHeight = (newHeight: number) => {
  creditLimitsOnForwardsAndOptionsHeight.value = newHeight;
};
</script>

<template>
  <div class="credit-mark-to-market-management">
    <LoadingOverlay
      :state="requestManager.requestStates.loadClientRiskProfileReport"
      show-retry
      @retry="loadClientRiskProfileReport"
    />
    <template v-if="clientRiskProfileReportData && clientRiskProfileReportData.creditProfile">
      <VRow>
        <VCol lg="6" sm="12">
          <TileCard tileTitle="Credit Utilisation">
            <CreditLimitsOnForwardsAndOptions
              :clientId="ownerId"
              :collateralProfileData="clientRiskProfileReportData.collateralProfile"
              :limitsProfileData="clientRiskProfileReportData.limitsProfile"
              @height-updated="getComponentHeight"
            />
          </TileCard>
        </VCol>
        <VCol lg="6" sm="12">
          <TileCard>
            <template #title>
              <WarningExclamationIcon v-if="isMTMNegative && !isMTMGreaterThanVMLimit" class="icon-size" />
              <ErrorExclamationIcon v-if="isMTMNegative && isMTMGreaterThanVMLimit" class="icon-size" />
              Your Mark-to-Market Position
            </template>
            <NoAvailableMarkToMarket
              v-if="isVMLimitAndMTMZeroAmount"
              :currency="clientRiskProfileReportData.collateralProfile.currency"
              :total-mark-to-market-amount="clientRiskProfileReportData.collateralProfile.totalMarkToMarket"
              :inheritedHeight="creditLimitsOnForwardsAndOptionsHeight"
            />
            <YourMTMPosition
              v-else
              :collateralProfileData="clientRiskProfileReportData.collateralProfile"
              :inheritedHeight="creditLimitsOnForwardsAndOptionsHeight"
            />
          </TileCard>
        </VCol>
      </VRow>
    </template>
    <TileCard v-if="canSeeTrades" tileTitle="Open Positions Mark-to-Market">
      <OpenPositionsMtmListing
        :sortAndPageParams.sync="defaultSortAndPageParams"
        :clientId="ownerId"
        paginationQueryParam="openPositionsMtmSort"
      />
    </TileCard>
  </div>
</template>

<style lang="scss" scoped>
.credit-mark-to-market-management {
  font-family: $font-family-inter;

  .icon-size {
    width: 1.3rem;
    height: 1.3rem;
    margin: 0 0.5rem;
  }

  ::v-deep .tile-card {
    margin-bottom: 3rem;
  }
}
</style>
