<template>
  <div class="form-flow-wrapper">
    <div class="form-holder">
      <slot />
    </div>
    <div class="bottom">
      <slot name="buttons" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FormFlowWrapper extends Vue {}
</script>

<style lang="scss" scoped>
.form-flow-wrapper {
  display: grid;
  height: 100%;
  grid-template-rows: 1fr min-content;
  grid-template-areas: 'main' 'bottom';

  .form-holder {
    grid-area: main;
    overflow: auto;
  }

  .bottom {
    grid-area: bottom;
    padding: 1.5rem;
    text-align: center;
  }
}
</style>
