<template>
  <AppBanner class="text-center info" v-if="shouldShowUpdate">
    <p class="mb-0">
      An update for this application is available. Please refresh your browser or
      <a class="link" @click="updateApp">click here to update.</a>
    </p>
  </AppBanner>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import AppBanner from '@/app/components/common/AppBanner.vue';
import { MINUTE } from 'ah-common-lib/src/constants/time';

const UPDATE_TIMEOUT = MINUTE * 30;

@Component({
  components: {
    AppBanner,
  },
})
export default class AppUpdaterBanner extends Vue {
  private shouldShowUpdate = false;

  private updateCheckTimeout: number | null = null;

  created() {
    /**
     * Updates the `shouldShowUpdate` value according to the following requirements (all must be true):
     * - App is not up to date
     * - last version check was at least UPDATE_TIMEOUT ago
     * - last update attempt was not to the latest version, or it was at least UPDATE_TIMEOUT ago
     */
    const updateShouldUpdate = () => {
      this.shouldShowUpdate =
        !this.$ahAppUpdaterState.isUpToDate &&
        this.$ahAppUpdaterState.lastVersionCheck + UPDATE_TIMEOUT < Date.now() &&
        (this.$ahAppUpdaterState.latestAttemptedVersion !== this.$ahAppUpdaterState.latestVersion ||
          this.$ahAppUpdaterState.latestUpdateAttempt + UPDATE_TIMEOUT < Date.now());
      this.updateCheckTimeout = window.setTimeout(updateShouldUpdate, MINUTE * 2);
    };
    updateShouldUpdate();
  }

  beforeDestroy() {
    if (this.updateCheckTimeout) {
      clearTimeout(this.updateCheckTimeout);
    }
  }

  updateApp() {
    this.$ahAppUpdaterState.reloadToUpdate(true);
  }
}
</script>
