import _Vue, { PluginObject } from 'vue';
import { TabSync } from './tabSync';

export default {
  install: function install(Vue: typeof _Vue) {
    const tabSync = new TabSync();

    Vue.tabSync = tabSync;
    Vue.prototype.$tabSync = tabSync;
  },
} as PluginObject<void>;
