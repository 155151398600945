<template>
  <span>
    <BModal modal-class="side-modal modal-md" ref="addIndividualModal" title="Invite user" v-bind="$attrs">
      <ValidatedForm :fm="emailForm" class="mt-3" :validation.sync="emailFV">
        <template #userForm.email:after>
          <p class="text-small text-muted mt-3">
            Your new user will receive an invite to join the team at this email address.
          </p>
        </template>
        <template #userForm.phoneNumber:after>
          <span class="text-muted">
            <ul class="text-small pl-4 my-1">
              <li>Must include country code</li>
            </ul>
          </span>
        </template>
      </ValidatedForm>
      <template #modal-footer>
        <div class="buttons text-left">
          <VButton class="btn-secondary mr-2" @click="cancel">Cancel</VButton>
          <VButton class="btn-success" @click="save" :loading="requestManager.anyPending">Invite User</VButton>
        </div>
      </template>
    </BModal>
    <slot v-bind="{ show }" />
  </span>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import { emailField, textField, phoneField } from 'ah-common-lib/src/form/models';
import { makeFormModel, submitForm, toDataModel } from 'ah-common-lib/src/form/helpers';
import { IndividualType, IndividualInvitation } from 'ah-api-gateways';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';
import { optional, checkParam, requiredIfStateValue, validName } from 'ah-common-lib/src/form/validators';
import { mergeMap, catchError } from 'rxjs/operators';
import { waitForEntityCreation } from 'ah-requests';
import { of } from 'rxjs';
import { FormValidation } from 'ah-common-lib/src/form/interfaces';
import { useAuthStore } from '@/app/store/authStore';
import { cloneDeep } from 'lodash';

const userFM = () =>
  makeFormModel({
    name: 'userForm',
    fieldType: 'form',
    fields: [
      textField(
        'firstName',
        'First Name',
        { fieldWrapperClass: 'col col-5', showRequiredMarkers: true },
        { required: requiredIfStateValue('firstName'), validName }
      ),
      textField(
        'lastName',
        'Last Name',
        { fieldWrapperClass: 'col col-5', showRequiredMarkers: true },
        { required: requiredIfStateValue('firstName'), validName }
      ),
      emailField('email', 'Email Address', { showRequiredMarkers: true }),
      phoneField(
        'phoneNumber',
        'Mobile Number',
        { class: 'col col-md-7' },
        {
          phone: optional(checkParam('phoneNumber', 'valid')),
        }
      ),
    ],
  });

@Component
export default class AddIndividualModal extends Mixins(WithRequestManager) {
  $refs!: {
    addIndividualModal: BModal;
  };

  private emailForm = userFM();

  emailFV: FormValidation | null = null;

  requestManagerConfig = {
    exposeToParent: true,
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'inviteIndividual') {
      this.save();
    }
  }

  get authStore() {
    return useAuthStore();
  }

  show() {
    this.emailForm = userFM();
    this.$refs.addIndividualModal.show();
  }

  cancel() {
    this.$refs.addIndividualModal.hide();
  }

  get invitationObject() {
    let out: IndividualInvitation = {
      type: IndividualType.CLIENT_INDIVIDUAL,
      ...toDataModel(this.emailForm),
    };

    if (this.authStore.isAgent) {
      out.type = IndividualType.PARTNER_AGENT;
    } else {
      out.type = IndividualType.CLIENT_INDIVIDUAL;
      out.client = {
        id: this.authStore.loggedInIdentity!.client?.id || '',
      };
    }

    return out;
  }

  private save() {
    if (this.emailFV) {
      submitForm(this.emailFV);
      if (this.emailFV.$invalid) {
        return;
      }

      const invitationIndividual = cloneDeep(this.invitationObject);
      if (invitationIndividual.phoneNumber?.length === 0) {
        delete invitationIndividual.phoneNumber;
      }

      this.requestManager
        .new(
          'inviteIndividual',
          this.$services.individual
            .inviteIndividual(invitationIndividual, { errors: { silent: true } })
            .pipe(
              mergeMap((idEntity) =>
                waitForEntityCreation(() =>
                  this.$services.individual.getIndividual(idEntity.id, { errors: { silent: true } })
                ).pipe(catchError(() => of(idEntity)))
              )
            )
        )

        .subscribe(
          () => {
            this.$toast.show('User invited successfully');
            this.$emit('individual-invited');
            this.$refs.addIndividualModal.hide();
          },
          (e) => {
            if (e.response.status === 400) {
              this.$toast.info(e.response.data.message);
              return;
            }
          }
        );
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .modal-dialog {
  width: 44em !important;
}

.buttons {
  width: 100%;
  margin-left: 3.1rem;
  margin-bottom: 2rem;
  .btn-secondary {
    min-width: 6rem;
  }
  .btn-success {
    min-width: 8rem;
  }
}
</style>
