<template>
  <div>
    <div :class="[{ 'card-block': withBoxes !== false }, 'individual-documents row']" v-if="showPersonal !== false">
      <h3 class="mt-3 col col-12" v-if="showTitles !== false">
        <slot name="title">
          {{ isCompany ? 'User Identification Documents' : 'Identification Documents' }}
        </slot>
      </h3>
      <FileUploader
        ref="idDocument"
        title="Proof of ID"
        :class="fileClass"
        :maxSize="10485760"
        :accept="defaultAcceptanceFileTypes"
        acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
        description="Valid, clear, colour copy of passport, or driving licence (all text data must be in frame, be visible and legible)"
        :uploadRequest="uploadID"
        :uploaded="photoId"
        :downloadRequest="downloadFile"
        :deleteRequest="deleteFile"
        :loading="reqManager.anyPending"
        hideFooter
        v-if="shouldShow(FileCategory.PHOTO_ID)"
        v-bind="$attrs"
      />
      <FileUploader
        ref="proofOfAddress"
        title="Proof of Address"
        :class="fileClass"
        :maxSize="10485760"
        description="Clear, colour copy of a utility bill (must be dated within the last 3 months)."
        :accept="defaultAcceptanceFileTypes"
        acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
        :uploadRequest="uploadPOA"
        :uploaded="proofOfAddress"
        :downloadRequest="downloadFile"
        :deleteRequest="deleteFile"
        :loading="reqManager.anyPending"
        hideFooter
        v-if="shouldShow(FileCategory.PROOF_OF_ADDRESS)"
        v-bind="$attrs"
      />
      <FileUploader
        v-if="!isCompany && shouldShow(FileCategory.PROOF_OF_FUNDS)"
        ref="proofOfFundDocument"
        title="Proof of Funds"
        :class="fileClass"
        :maxSize="10485760"
        :accept="defaultAcceptanceFileTypes"
        acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
        description="Itemized bank statements, to prove source of wealth (must be dated within 3 months)"
        :uploadRequest="uploadPOF"
        :uploaded="proofOfFunds"
        :downloadRequest="downloadFile"
        :deleteRequest="deleteFile"
        :loading="reqManager.anyPending"
        hideFooter
        v-bind="$attrs"
      />
      <div class="my-3" v-if="!isCompany || withBoxes !== false">
        <p class="text-secondary small-text mb-0">Max size: 10 MB</p>
        <p class="text-secondary small-text mb-0">
          Supported formats: <span class="inline small-text">.PDF, .BMP, .JPEG, .GIF, .TIF, .PNG</span>
        </p>
      </div>
    </div>

    <div
      :class="[{ 'card-block': withBoxes !== false }, { 'mt-3': showPersonal !== false }, 'company-documents row']"
      v-if="isCompany && !shouldForceIndividual"
    >
      <h3 class="col col-12" v-if="showTitles !== false">Company Documents</h3>
      <FileUploader
        ref="incCertificateDocument"
        title="Certificate of Incorporation"
        :maxSize="10485760"
        :class="fileClass"
        description="Legal document or license relating to the formation of a company issued by state government or a non-governmental entity/corporation."
        :accept="defaultAcceptanceFileTypes"
        acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
        :uploadRequest="uploadINCC"
        :uploaded="IncCertificate"
        :downloadRequest="downloadFile"
        :deleteRequest="deleteFile"
        :loading="reqManager.anyPending"
        hideFooter
        v-if="shouldShow(FileCategory.INCORPORATED_DOCUMENT)"
        v-bind="$attrs"
      />
      <FileUploader
        ref="financialStatementDocument"
        title="Latest Accounts / Audited Financial Statements"
        :maxSize="10485760"
        :accept="defaultAcceptanceFileTypes"
        :class="fileClass"
        acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
        description="Company bank statement showing the last 3 months or, latest audited financial statements."
        :uploadRequest="uploadFS"
        :uploaded="financialStatement"
        :downloadRequest="downloadFile"
        :deleteRequest="deleteFile"
        :loading="reqManager.anyPending"
        hideFooter
        v-if="shouldShow(FileCategory.FINANCIAL_STATEMENT)"
        v-bind="$attrs"
      />
      <FileUploader
        ref="sampleInvoiceDocument"
        title="Proof of Business Activity"
        :maxSize="10485760"
        :accept="defaultAcceptanceFileTypes"
        :class="fileClass"
        acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
        description="Sample invoice(s) required to prove the company is actively trading."
        :uploadRequest="uploadSI"
        :uploaded="invoiceSample"
        :downloadRequest="downloadFile"
        :deleteRequest="deleteFile"
        :loading="reqManager.anyPending"
        hideFooter
        v-if="shouldShow(FileCategory.SAMPLE_INVOICE)"
        v-bind="$attrs"
      />
      <div class="my-3">
        <p class="text-secondary small-text mb-0">Max size: 10 MB</p>
        <p class="text-secondary small-text mb-0">
          Supported formats: <span class="inline small-text">.PDF, .BMP, .JPEG, .GIF, .TIF, .PNG</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { ClientFileCategories, UploadedFile, Client, ClientType, FileCategory } from 'ah-api-gateways';
import FileUploader from 'ah-common-lib/src/common/components/upload/FileUploader.vue';
import { map, mergeMap, tap } from 'rxjs/operators';
import { RequestManager, waitForEntityChange } from 'ah-requests';
import { defaultAcceptanceFileTypes } from 'ah-common-lib/src/helpers/file';
import { useAuthStore } from '@/app/store/authStore';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { of } from 'rxjs';

/**
 * Client Proof of Address and Identification documents uploader
 *
 * FIXME deprecated, use generic ClientDocumentsUploader instead
 *
 * Emits:
 *
 * - update:files (payload: UploadedFile[]): emmited when one of the files gets changed. Can be used with .sync
 */
@Component({
  components: {
    FileUploader,
  },
})
export default class DocumentsUploader extends Vue {
  /**
   * Files presented to client, if not set files will be loaded on mount
   *
   * Defaults to null
   */
  @Prop({ default: null }) files!: UploadedFile[] | null;

  /**
   * Whether to display individual documents
   *
   * Defaults to true
   */
  @Prop({ default: true }) showPersonal!: boolean | string;

  /**
   * Whether to display title above each document section
   *
   * Defaults to true
   */
  @Prop({ default: true }) showTitles!: boolean | string;

  /**
   * Whether to force individual documents only
   *
   * Defaults to false
   */
  @Prop({ default: false }) forceIndividual!: boolean | string;

  /**
   * Whether to display both section surrounded by card-boxes
   *
   * Defaults to false
   */
  @Prop({ default: false }) withBoxes!: boolean | string;

  /**
   * List of files to hide by FileCategory
   *
   * Defaults to empty string
   */
  @Prop({ default: () => [] }) hideFiles!: FileCategory[];

  /**
   * Whether to hide size limit and allowed files on bottom
   *
   * Defaults to true
   */
  @Prop({ default: true }) hideFooter!: boolean | string;

  /**
   * Possibly specifie the client that owns the displayed documents
   */
  @Prop({ required: false }) client?: Client;

  /**
   * Class to be applied to each file row
   *
   * Defaults to 'col col-12'
   */
  @Prop({ default: 'col col-12' }) fileClass!: string;

  $refs!: {
    proofOfAddress: FileUploader;
    idDocument: FileUploader;
  };

  private reqManager = new RequestManager();

  private innerFiles: UploadedFile[] = [];

  private defaultAcceptanceFileTypes = defaultAcceptanceFileTypes;

  created() {
    if (!this.files || this.files.length === 0) {
      this.reqManager
        .sameOrCancelAndNew(
          'getClientDocuments',
          this.$services.client.getDocuments(this.clientId, { errors: { silent: true } })
        )
        .subscribe((response) => {
          this.innerFiles = response;
          response.forEach((document) => this.individualSettingsStore.setClientDocument({ document }));
        });
    }
  }

  get authStore() {
    return useAuthStore();
  }

  get individualSettingsStore() {
    return useIndividualSettingsStore();
  }

  get photoId() {
    return this.innerFiles.find((document) => document.category === ClientFileCategories.PHOTO_ID) || null;
  }

  get proofOfAddress() {
    return this.innerFiles.find((document) => document.category === ClientFileCategories.PROOF_OF_ADDRESS) || null;
  }

  get proofOfFunds() {
    return this.innerFiles.find((document) => document.category === ClientFileCategories.PROOF_OF_FUNDS) || null;
  }

  get IncCertificate() {
    return this.innerFiles.find((document) => document.category === ClientFileCategories.INCORPORATED_DOCUMENT) || null;
  }

  get financialStatement() {
    return this.innerFiles.find((document) => document.category === ClientFileCategories.FINANCIAL_STATEMENT) || null;
  }

  get invoiceSample() {
    return this.innerFiles.find((document) => document.category === ClientFileCategories.SAMPLE_INVOICE) || null;
  }

  get anyPending() {
    return this.$refs.proofOfAddress?.isUploading || this.$refs.idDocument?.isUploading;
  }

  get shouldForceIndividual() {
    return this.forceIndividual !== false;
  }

  get isCompany() {
    return (this.client && this.client?.type === ClientType.COMPANY) || this.authStore.isCompanyClient;
  }

  get clientId() {
    return this.client?.id || this.authStore.loggedInIdentity!.client!.id;
  }

  get FileCategory() {
    return ClientFileCategories;
  }

  get fileCategories() {
    if (this.isCompany && !this.shouldForceIndividual) {
      return [
        ClientFileCategories.PROOF_OF_ADDRESS,
        ClientFileCategories.PHOTO_ID,
        ClientFileCategories.INCORPORATED_DOCUMENT,
        ClientFileCategories.FINANCIAL_STATEMENT,
        ClientFileCategories.SAMPLE_INVOICE,
      ];
    } else {
      if (this.isCompany) {
        return [ClientFileCategories.PROOF_OF_ADDRESS, ClientFileCategories.PHOTO_ID];
      } else {
        return [
          ClientFileCategories.PROOF_OF_ADDRESS,
          ClientFileCategories.PHOTO_ID,
          ClientFileCategories.PROOF_OF_FUNDS,
        ];
      }
    }
  }

  private shouldShow(category: FileCategory) {
    return !this.hideFiles.includes(category);
  }

  uploadFile(file: File, category: ClientFileCategories) {
    return this.$services.client.uploadDocument(this.clientId, category, file).pipe(
      mergeMap((file) => {
        if (file.finished) {
          return waitForEntityChange(
            () => this.$services.client.getDocuments(this.clientId),
            (docs) => {
              return !!docs.find((d) => d.id === file.file.id);
            }
          ).pipe(
            map((docs) => {
              const document = docs.find((d) => d.id === file.file.id);
              if (document) {
                const currFileIndex = this.innerFiles.findIndex((i) => i.category === document.category);
                currFileIndex > -1
                  ? this.innerFiles.splice(currFileIndex, 1, document)
                  : this.innerFiles.push(document);
              }
              return file;
            })
          );
        }
        return of(file);
      })
    );
  }

  deleteFile(file: UploadedFile) {
    return this.$services.client.deleteDocument(this.clientId, file.id).pipe(
      tap(() => {
        const currFileIndex = this.innerFiles.findIndex((i) => i.category === file.category);
        if (currFileIndex > -1) {
          this.innerFiles.splice(currFileIndex, 1);
        }

        this.individualSettingsStore.unsetClientDocument({ category: file.category as ClientFileCategories });
      })
    );
  }

  downloadFile(file: UploadedFile) {
    return this.$services.client.downloadSyncDocument(this.clientId, file);
  }

  uploadID(file: File) {
    return this.uploadFile(file, ClientFileCategories.PHOTO_ID);
  }

  uploadPOA(file: File) {
    return this.uploadFile(file, ClientFileCategories.PROOF_OF_ADDRESS);
  }

  uploadPOF(file: File) {
    return this.uploadFile(file, ClientFileCategories.PROOF_OF_FUNDS);
  }

  uploadINCC(file: File) {
    return this.uploadFile(file, ClientFileCategories.INCORPORATED_DOCUMENT);
  }

  uploadFS(file: File) {
    return this.uploadFile(file, ClientFileCategories.FINANCIAL_STATEMENT);
  }

  uploadSI(file: File) {
    return this.uploadFile(file, ClientFileCategories.SAMPLE_INVOICE);
  }

  @Watch('innerFiles')
  onInnerFilesChange() {
    this.$emit('update:files', this.innerFiles);
  }

  @Watch('files', { immediate: true })
  onFilesChange() {
    if (this.files !== null) {
      this.innerFiles = this.files;
    }
  }
}
</script>

<style lang="scss" scoped>
.separator {
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  @include themedPropColor('border-bottom-color', $color-input-border);
}
</style>
