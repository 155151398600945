<template>
  <FormElement class="search-input" :field="v$.searchForm.search" :model="searchModel" @form-event="onSearchChange">
    <template #searchForm.search:prepend>
      <BInputGroupText key="searchForm.search:prepend">
        <IconSearch />
      </BInputGroupText>
    </template>
    <template #searchForm.search:append v-if="searchForm.search">
      <BInputGroupText key="searchForm.search:append" class="reset-input" @click="resetSearch">
        <IconCircleX />
      </BInputGroupText>
    </template>
  </FormElement>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { makeFormValidation, makeFormModel, getChildModel, setState } from 'ah-common-lib/src/form/helpers';
import { textField } from 'ah-common-lib/src/form/models';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormEvent } from 'ah-common-lib/src/form/interfaces';
import useVuelidate, { Validation } from '@vuelidate/core';

const searchFM = () =>
  makeFormModel({
    name: 'searchForm',
    fieldType: 'form',
    fields: [
      textField(
        'search',
        '',
        {
          // Setting input name directly to avoid pass manager autocomple nonsense
          inputName: `beneficiary-search-box`,
          placeholder: 'Search...',
          autocomplete: 'off',
        },
        {}
      ),
    ],
  });

@Component({
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations: {
    searchForm: makeFormValidation(searchFM()),
  },
})
export default class SearchInput extends Vue {
  @Prop({ default: '' }) search!: string;

  @Prop({ default: 'Search...' }) placeholder!: string;

  @Prop({ default: false }) stroked!: boolean | string;

  private v$!: Validation;

  searchForm = searchFM();

  private searchTextSubject = new Subject<string>();

  private searchSubscription?: Subscription;

  beforeMount() {
    this.searchSubscription = this.searchTextSubject.pipe(debounceTime(700)).subscribe((search: string) => {
      if (search === this.searchForm.search) {
        this.$emit('update:search', search);
      }
    });
  }

  onSearchChange(formEvent: FormEvent) {
    if (formEvent.event === 'form-field-set-value') {
      this.searchTextSubject.next(this.searchForm.search);
    }
  }

  @Watch('stroked', { immediate: true })
  onStrokedChange() {
    this.searchForm.$fields[0].$state.inputGroupClass = this.stroked !== false ? '' : 'borderless';
  }

  @Watch('placeholder', { immediate: true })
  onPlaceholderChange() {
    if (this.searchModel) {
      setState(this.searchModel, 'placeholder', this.placeholder);
    }
  }

  @Watch('search', { immediate: true })
  onSearchPropChange(search: string) {
    this.searchForm.search = search;
  }

  beforeDestroy() {
    this.searchSubscription!.unsubscribe();
  }

  resetSearch() {
    this.searchForm.search = '';
    this.$emit('update:search', this.searchForm.search);
  }

  get searchModel() {
    return getChildModel(this.searchForm, 'search');
  }
}
</script>

<style lang="scss" scoped>
.reset-input {
  cursor: pointer;

  svg {
    opacity: 0.4;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
}
</style>
