import { HttpService, HttpBasedService, HttpOptions } from 'ah-requests';
import { ExpiryTime } from '../models/expiry';

export class AccountService extends HttpBasedService {
  constructor(http: HttpService, private baseUrl: string) {
    super(http, {
      options: {
        errors: { messageDefaults: { group: 'accountService' } },
      },
    });
  }

  public resetPassword(data: { email: string }, options?: Partial<HttpOptions<void>>) {
    return this.post<void>(`${this.baseUrl}account/password/reset`, data, { options: { ...options, skipAuth: true } });
  }

  public confirmResetPassword(data: { token: string; newPassword: string }, options?: Partial<HttpOptions<void>>) {
    return this.put<void>(`${this.baseUrl}account/password/reset`, data, { options: { ...options, skipAuth: true } });
  }

  public getEmailForPasswordResetToken(token: string, options?: Partial<HttpOptions<{ email: string }>>) {
    return this.get<{ email: string }>(`${this.baseUrl}account/password/reset/${token}`, {
      options: { ...options, skipAuth: true },
    });
  }

  public changePassword(oldPassword: string, newPassword: string, otp?: string, options?: Partial<HttpOptions<void>>) {
    const headers: Record<string, string> = {
      'content-type': 'application/json;charset=UTF-8',
    };
    // FIXME waiting on API change to remove header - currently will get a 401 if header isn't sent
    // if (otp) {
    headers['x-ah-otp'] = otp ?? '';
    // }

    return this.put<void>(
      `${this.baseUrl}account/password`,
      { oldPassword, newPassword },
      {
        options,
        axiosConfig: {
          headers,
        },
      }
    );
  }

  public changeContacts(
    contacts: { phoneNumber?: string; email?: string },
    otp?: string,
    options?: Partial<HttpOptions<void>>
  ) {
    const headers: Record<string, string> = {
      'content-type': 'application/json;charset=UTF-8',
    };
    // FIXME waiting on API change to remove header - currently will get a 401 if header isn't sent
    // if (otp) {
    headers['x-ah-otp'] = otp ?? '';
    // }

    return this.put<void>(`${this.baseUrl}account/contacts`, contacts, {
      options,
      axiosConfig: {
        headers,
      },
    });
  }

  public refreshOtpForNumberChange(options?: Partial<HttpOptions<ExpiryTime>>) {
    return this.put<ExpiryTime>(`${this.baseUrl}account/contacts/verify/phonenumber`, { options });
  }

  public verifyOtpForNumberChange(otp: string, options?: Partial<HttpOptions<void>>) {
    return this.post<void>(`${this.baseUrl}account/contacts/verify/phonenumber`, undefined, {
      options,
      axiosConfig: {
        headers: {
          'content-type': 'application/json;charset=UTF-8',
          'x-ah-otp': otp,
        },
      },
    });
  }

  public resendEmailForChange(options?: Partial<HttpOptions<void>>) {
    return this.put<void>(`${this.baseUrl}account/contacts/verify/email`, undefined, {
      options,
    });
  }

  public verifyEmailChange(token: string, options?: Partial<HttpOptions<void>>) {
    return this.post<void>(`${this.baseUrl}account/contacts/verify/email`, undefined, {
      options,
      axiosConfig: {
        params: { token },
      },
    });
  }
}
