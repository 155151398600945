import { render, staticRenderFns } from "./CollapsibleCard.vue?vue&type=template&id=284c2760&scoped=true"
import script from "./CollapsibleCard.vue?vue&type=script&lang=ts"
export * from "./CollapsibleCard.vue?vue&type=script&lang=ts"
import style0 from "./CollapsibleCard.vue?vue&type=style&index=0&id=284c2760&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "284c2760",
  null
  
)

export default component.exports