<script setup lang="ts">
import { computed, reactive, watch } from 'vue';
import { FormValidation } from 'ah-common-lib/src/form/interfaces';
import TermsAcceptanceForm from '../../registration/forms/common/TermsAcceptanceForm.vue';
import OBOSettingsForm from '../../registration/forms/common/OBOSettingsForm.vue';
import { AuthorityType, Client, Permission } from 'ah-api-gateways';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { forkJoin } from 'rxjs';
import { useAuthStore } from '@/app/store/authStore';

const requestManager = useRequestManager().manager;

const services = getServices();

const props = withDefaults(defineProps<{ client: Client; withOBOPermissions?: boolean | string }>(), {
  withOBOPermissions: false,
});

const emit = defineEmits<{ (e: 'approved'): void }>();

const authStore = useAuthStore();

const state = reactive<{
  termsValidation: FormValidation | null;
  oboValidation?: FormValidation<Permission[]>;
  permissions: Permission[];
}>({
  permissions: [
    { authority: AuthorityType.PAY_ON_BEHALF_OF, allow: false },
    { authority: AuthorityType.TRADE_ON_BEHALF_OF, allow: false },
  ],
  termsValidation: null,
});

const anyInvalid = computed(() => state.termsValidation?.$invalid || state.oboValidation?.$invalid);

function hasPermission(authority: AuthorityType) {
  return !!props.client.permissions?.find((p) => p === authority);
}

const clientId = computed(() => authStore.loggedInIdentity!.client!.id);

function savePermissions() {
  if (props.withOBOPermissions !== false) {
    return requestManager
      .cancelAndNew(
        'savePermissions',
        forkJoin(state.permissions.map((p) => services.authz.setClientsPermissions(clientId.value, p)))
      )
      .toPromise();
  }
  return Promise.resolve();
}

function approveClient() {
  return requestManager
    .sameOrCancelAndNew('approveClient', services.client.acceptTermsAndConditions(props.client.id))
    .toPromise();
}

function submit() {
  savePermissions()
    .then(approveClient)
    .then(() => emit('approved'));
}

watch(
  () => props.client,
  () => {
    state.permissions.forEach((p) => {
      p.allow = hasPermission(p.authority);
    });
  },
  { immediate: true }
);
</script>

<template>
  <div class="terms-wrapper" x-test-name="signatory-terms-review">
    <h2>Terms and Conditions</h2>
    <OBOSettingsForm
      v-if="withOBOPermissions !== false"
      class="mb-3"
      :permissions.sync="state.permissions"
      :validation.sync="state.oboValidation"
    />
    <TermsAcceptanceForm :validation.sync="state.termsValidation" />
    <div class="text-center btn-wrapper">
      <VButton
        blurOnClick
        @click="submit"
        :loading="requestManager.requestStates.approveClient === 'pending'"
        :disabled="anyInvalid"
      >
        Continue
      </VButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.terms-wrapper {
  max-width: 40rem;
  margin: 0 auto;
  .btn-wrapper {
    padding: 1rem 4rem;
    .btn {
      width: 100%;
    }
  }
}
</style>
