import { CreatableObject, UpdatableObject, VersionedObject } from './rest';
import { TenorLimits } from './tenorLimits';

export enum PositionLimitRequestStatus {
  OPEN = 'OPEN',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum PositionLimitStatus {
  OPEN = 'OPEN',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  ACTIVE = 'ACTIVE',
  AMENDED = 'AMENDED',
  EXPIRED = 'EXPIRED',
}

export interface PositionLimitsData {
  partnerId: string;
  clientId: string;
  notionalLimitSpots: number;
  notionalLimitForwardsOptions: number;
  tenorLimitForwards: TenorLimits;
  tenorLimitOptions: TenorLimits;
  currency: string;
  expirationDate: string;
}
export interface PositionLimitsProfile extends VersionedObject, CreatableObject, UpdatableObject {
  partnerId: string;
  partnerName: string;
  clientId: string;
  clientName: string;
  notionalLimitSpots: number;
  notionalLimitForwardsOptions: number;
  notionalLimitForwardsOptionsInUse: number;
  notionalLimitForwardsOptionsUsage: number;
  notionalLimitSpotsInUse: number;
  notionalLimitSpotsUsage: number;
  notionalLimitSpotsRemaining: number;
  notionalLimitForwardsOptionsRemaining: number;
  tenorLimitForwards: TenorLimits;
  tenorLimitOptions: TenorLimits;
  currency: string;
  expirationDate: string;
}

export interface PositionLimitsRequest {
  clientId: string;
  notionalLimitSpots: number;
  notionalLimitForwardsOptions: number;
  tenorLimitForwards: TenorLimits;
  tenorLimitOptions: TenorLimits;
  currency: string;
  expirationDate: string;
}

export interface PositionUsageReport {
  notionalLimitSpots: number;
  notionalLimitSpotsInUse: number;
  notionalLimitSpotsUsage: number;
  notionalLimitForwardsOptions: number;
  notionalLimitForwardsOptionsInUse: number;
  notionalLimitForwardsOptionsUsage: number;
  currency: string;
}

export interface ClientPositionUsageReport extends PositionUsageReport {
  clientId: string;
  clientName: string;
  partnerId: string;
  partnerName: string;
}

export interface ClientRiskProfileReportParams {
  clientId: string;
  oboClientId?: string;
}

export interface ClientRiskProfileReport {
  collateralProfile: ClientRiskCollateralProfile;
  creditProfile?: ClientRiskCreditProfile;
  limitsProfile?: ClientRiskLimitsProfile;
}

interface ClientRiskProfileCommon extends Partial<VersionedObject>, Partial<CreatableObject>, Partial<UpdatableObject> {
  clientId: string;
  clientName: string;
  currency: string;
  partnerId: string;
  partnerName: string;
}

export interface ClientRiskCollateralProfile extends ClientRiskProfileCommon {
  activeMarginCall: boolean;
  availableCollateral: number;
  clientType: string;
  creditUsage: number;
  eurPostedCollateral: number;
  eurPostedCollateralCurrencyEquivalent: number;
  fromEurConversionRate: number;
  fromGbpConversionRate: number;
  fromUsdConversionRate: number;
  gbpPostedCollateral: number;
  gbpPostedCollateralCurrencyEquivalent: number;
  netEquityAfterCollateral: number;
  totalCollateralInUse: number;
  totalInitialMarginCollateralFunding: number;
  totalInitialMarginCreditFunding: number;
  totalInitialMarginFunding: number;
  totalMarkToMarket: number;
  totalPostedCollateral: number;
  totalPostedCollateralRemaining: number;
  totalTradesAmount: number;
  usdPostedCollateral: number;
  usdPostedCollateralCurrencyEquivalent: number;
  variationMarginAmount: number;
  variationMarginAmountRequired: number;
  variationMarginExcessAmount: number;
  variationMarginIncrementAmount: number;
  variationMarginIncrementPercentage: number;
  variationMarginIncrementsRequired: number;
  variationMarginPercentage: number;
  maxPostedCollateralWithdrawable: number;
  minCreditUsageForDisablingCollateralWithdrawal: number;
}

export interface ClientRiskCreditProfile extends ClientRiskProfileCommon {
  creditExpirationDate: string;
  marginCreditLimitTotal: number;
  marginCreditLimitTotalInUse: number;
  marginCreditLimitTotalUsage: number;
  marginCreditLimitTotalRemaining: number;
}

export interface ClientRiskLimitsProfile extends ClientRiskProfileCommon {
  limitsExpirationDate: string;
  notionalLimitForwardsOptions: number;
  notionalLimitForwardsOptionsInUse: number;
  notionalLimitForwardsOptionsUsage: number;
  notionalLimitForwardsOptionsRemaining: number;
  notionalLimitSpots: number;
  notionalLimitSpotsInUse: number;
  notionalLimitSpotsUsage: number;
  notionalLimitSpotsRemaining: number;
  tenorLimitForwards: string;
  tenorLimitOptions: string;
}

export interface PositionLimitAuditProfile extends PositionLimitsProfile {
  amendmentReason: string;
  amendedAt: string;
  amendedBy: string;
}

export interface PositionLimitAudit {
  id: string;
  clientId: string;
  partnerId: string;
  currency: string;
  notionalLimitForwardsOptions: number;
  notionalLimitSpots: number;
  profile: PositionLimitAuditProfile;
  status: PositionLimitStatus;
}

export interface PositionLimitFilters {
  partnerName?: string;
  clientName?: string;
  clientType?: string;
  countryCode?: string;
  query?: string;
  status?: PositionLimitStatus;
  currency?: string;
  spotsIm?: boolean;
}
