<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import {
  ClientFileCategories,
  ClientFileCategoriesToHuman,
  ClientFileCategoryDescriptions,
  UploadedFile,
} from 'ah-api-gateways';
import FileUploader from 'ah-common-lib/src/common/components/upload/FileUploader.vue';
import { mergeMap, tap, map } from 'rxjs/operators';
import { waitForEntityChange } from 'ah-requests';
import { of } from 'rxjs';
import { getServices } from '@/app/services';
import { useAuthStore } from '@/app/store/authStore';
import { useIndividualSettingsStore } from '@/app/store/individualSettingsModule';
import { defaultAcceptanceFileTypes } from 'ah-common-lib/src/helpers/file';
import { isEqual } from 'lodash';
import { useFEFeatureFlag } from 'ah-common-stores';

const authStore = useAuthStore();

const individualSettingsStore = useIndividualSettingsStore();

const props = withDefaults(
  defineProps<{
    files?: UploadedFile[];
    categories?: ClientFileCategories[];
    title?: string;
    hideTypeInfo?: boolean | string;
  }>(),
  {
    hideTypeInfo: false,
    files: () => [],
    categories: () => [
      ClientFileCategories.PHOTO_ID,
      ClientFileCategories.PROOF_OF_ADDRESS,
      ClientFileCategories.PROOF_OF_FUNDS,
    ],
    title: 'User Identification Documents',
  }
);

const emit = defineEmits<{
  (e: 'update:files', value: UploadedFile[]): void;
}>();

const hideOnboardingDocumentsUploader = useFEFeatureFlag('hideOnboardingDocumentsUploader');

const services = getServices();

const filteredCategories = computed(() => {
  if (!hideOnboardingDocumentsUploader.value) {
    return props.categories;
  } else {
    return props.categories?.filter((category) => category === ClientFileCategories.PROOF_OF_FUNDS);
  }
});

const clientId = computed(() => authStore.loggedInIdentity!.client!.id);

const filesInner = ref<UploadedFile[]>([]);

function getFile(category: ClientFileCategories) {
  return filesInner.value.find((document) => document.category === category) || null;
}

function uploadFile(file: File, category: ClientFileCategories) {
  return services.client.uploadDocument(clientId.value, category, file).pipe(
    mergeMap((file) => {
      if (file.finished) {
        return waitForEntityChange(
          () => services.client.getDocuments(clientId.value),
          (docs) => {
            return !!docs.find((d) => d.id === file.file.id);
          }
        ).pipe(
          map((docs) => {
            const document = docs.find((d) => d.id === file.file.id);
            if (document) {
              const currFileIndex = filesInner.value.findIndex((i) => i.category === document.category);
              currFileIndex > -1
                ? filesInner.value.splice(currFileIndex, 1, document)
                : filesInner.value.push(document);
            }
            return file;
          })
        );
      }
      return of(file);
    })
  );
}

function deleteFile(file: UploadedFile) {
  return services.client.deleteDocument(clientId.value, file.id).pipe(
    tap(() => {
      const currFileIndex = filesInner.value.findIndex((i) => i.category === file.category);
      if (currFileIndex > -1) {
        filesInner.value.splice(currFileIndex, 1);
      }

      individualSettingsStore.unsetClientDocument({ category: file.category as ClientFileCategories });
    })
  );
}

function downloadFile(file: UploadedFile) {
  return services.client.downloadSyncDocument(clientId.value, file);
}

watch(
  () => props.files,
  () => {
    filesInner.value = [...props.files];
  },
  { immediate: true }
);

watch(
  filesInner,
  () => {
    if (!isEqual(filesInner.value, props.files)) {
      emit('update:files', filesInner.value);
    }
  },
  { deep: true }
);
</script>

<template>
  <div class="row">
    <FileUploader
      v-for="category in filteredCategories"
      :key="category"
      tooltip
      class="col col-12"
      :accept="defaultAcceptanceFileTypes"
      acceptString=".PDF, .BMP, .JPEG, .GIF, .TIF, .PNG"
      :title="ClientFileCategoriesToHuman[category]"
      :description="ClientFileCategoryDescriptions[category]"
      :maxSize="10485760"
      :uploadRequest="(file) => uploadFile(file, category)"
      :deleteRequest="deleteFile"
      :downloadRequest="downloadFile"
      :uploaded="getFile(category)"
      hideFooter
      v-bind="$attrs"
    />
    <div class="my-3" v-if="hideTypeInfo === false">
      <p class="text-secondary small-text mb-0">Max size: 10 MB</p>
      <p class="text-secondary small-text mb-0">
        Supported formats: <span class="inline small-text">.PDF, .BMP, .JPEG, .GIF, .TIF, .PNG</span>
      </p>
    </div>
  </div>
</template>
