<template>
  <div class="info-block" v-if="settings">
    <div class="rows-block">
      <h4>Markups</h4>
      <DataRow lg="4" md="6" label="Spot" class="text-wrap mb-2 mt-2 fx-pot">
        <span class="percentage-text text-nowrap">{{ settings.fxSpots.profitMargin / 100 }}%</span>
      </DataRow>
      <DataRow lg="4" md="6" label="Fixed Forward" class="text-wrap mb-2 fx-fixed-forward">
        <span class="percentage-text text-nowrap">{{ settings.fxForwards.profitMargin / 100 }}%</span>
      </DataRow>
      <DataRow lg="4" md="6" label="Flexible Forward" class="mb-2 fx-flexible-forward">
        <span class="percentage-text text-nowrap">{{ settings.flexibleForwards.profitMargin / 100 }}%</span>
      </DataRow>
      <DataRow lg="4" md="6" label="Window Forward" class="text-wrap mb-4 fx-window-forward">
        <span class="percentage-text text-nowrap">{{ settings.windowForwards.profitMargin / 100 }}%</span>
      </DataRow>
    </div>

    <div class="text-center">
      <ClientMarginEditModal
        @update:client-settings="onClientUpdated($event)"
        :client="client"
        :settings="settings"
        v-slot="{ show }"
      >
        <VButton @click="show" class="btn-secondary">Edit Markups</VButton>
      </ClientMarginEditModal>
    </div>
  </div>
</template>

<script lang="ts">
import { Client, FxMargins } from 'ah-api-gateways';
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import ClientMarginEditModal from '../ClientMarginEditModal.vue';
import WithRequestManager from 'ah-common-lib/src/requestManager/WithRequestManager.vue';

@Component({
  components: {
    ClientMarginEditModal,
  },
})
export default class ClientMarkupInfo extends Mixins(WithRequestManager) {
  @Prop({ default: null }) client!: Client | null;

  private settings: FxMargins | null = null;

  requestManagerConfig = {
    exposeToParent: true,
    onRetryFromParentManager: this.onRetryFromParentManager,
  };

  onRetryFromParentManager(k: string) {
    if (k === 'getClientSettings') {
      this.onClientChange();
    }
  }

  @Watch('client', { immediate: true })
  onClientChange() {
    if (this.client) {
      this.requestManager
        .sameOrCancelAndNew('getClientSettings', this.$services.spreads.getClientSpreads(this.client.id))
        .subscribe((settings) => (this.settings = settings));
    }
  }

  onClientUpdated(settings: FxMargins) {
    this.settings = settings;
  }
}
</script>
