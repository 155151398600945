<script setup lang="ts">
import { required } from '@vuelidate/validators';
import { makeFormModel, submitForm } from 'ah-common-lib/src/form/helpers';
import { FormEvent } from 'ah-common-lib/src/form/interfaces';
import { email } from 'ah-common-lib/src/form/validators';
import { reactive, ref } from 'vue';
import { useTheme } from 'ah-theme';
import { useRequestManager } from 'ah-common-lib/src/requestManager/useRequestManager';
import { getServices } from '@/app/services';
import { FormDefinition } from 'ah-common-lib/src/form/interfaces';

const theme = useTheme();

const requestManager = useRequestManager().manager;

const services = getServices();

const forgottenPasswordForm = reactive<FormDefinition>({
  form: makeFormModel({
    name: 'ForgottenPasswordForm',
    fieldType: 'form',
    fields: [
      {
        name: 'email',
        title: 'Email',
        fieldType: 'text',
        errorMessages: {
          required: 'Email is required',
        },
        validators: {
          required,
          email,
        },
      },
    ],
  }),
  validation: null,
});

const emailSent = ref<boolean>(false);

function onFormEvent(formEvent: FormEvent) {
  if (formEvent.event === 'form-field-submit') {
    submit();
  }
}

function supportEmail() {
  theme.val?.helpEmail;
}

function submit() {
  if (forgottenPasswordForm.validation?.$invalid) {
    submitForm(forgottenPasswordForm.validation);
    return;
  }

  const payload = { email: forgottenPasswordForm.form.email };

  requestManager.sameOrCancelAndNew('resetPassword', services.account.resetPassword(payload), payload).subscribe(() => {
    emailSent.value = true;
  });
}
</script>

<template>
  <SimpleCenteredPage fullHeight narrow id="forgotten-password-view">
    <div v-if="!emailSent" class="main-area narrow mt-5">
      <h1 class="mb-5">Reset password</h1>
      <p class="mb-1">The security of your account is important to us.</p>
      <p class="mb-4">
        To reset your password, enter your email address. If you have an existing
        {{ $theme.val.name }} account, please use these details to sign in.
      </p>
      <ValidatedForm
        class="rounded-fields"
        :fm="forgottenPasswordForm.form"
        :validation.sync="forgottenPasswordForm.validation"
        @form-event="onFormEvent"
      />
      <div class="form-actions d-flex justify-content-center align-items-center mt-5">
        <VButton :loading="requestManager.anyPending" @click="submit" class="btn-primary"> Get reset link </VButton>
      </div>
    </div>
    <div v-else class="main-area narrow mt-5">
      <h1 class="mb-3">Email sent</h1>
      <p class="mb-4">
        Check your {{ forgottenPasswordForm.form.email }} inbox for instructions from us on how to reset your password.
      </p>
      <div class="separator margin-bottom">
        <hr />
      </div>
    </div>
    <template #footer>
      <p v-if="!emailSent">
        Already have an account? <RouterLink class="link back-to-login" :to="'/login'">Login</RouterLink>.
      </p>
      <p v-else>
        Unable to reset your password?
        <a :href="`mailto:${supportEmail}?subject=Account access support`">Contact us.</a>
      </p>
    </template>
  </SimpleCenteredPage>
</template>

<style lang="scss" scoped>
.margin-bottom {
  margin-bottom: 2em;
}
</style>
