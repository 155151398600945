export default {
  en: {
    login: {
      forgotPassword: 'Forgot your password?',
      register: 'Register',
      dontHaveAccount: "Don't have an account?",
      resendCode: 'Resend code.',
      twoStepAuth: 'Two-step authentication',
      didntReceive: `Didn't receive the message?`,
    },
    tooltips: {
      wallets:
        'Your account enables you to hold balances in multiple currencies, known as wallets.  These funds can be used to settle trades or sent to a beneficiary.',
      spot: 'A Spot Trade is an FX transaction which contractually obliges you to convert a currency at the quoted rate. The rate is sometimes referred to as the spot price  - and it is for immediate delivery.',
      drawdownFlexibleInfo:
        'Choose Fully Flexible if you want a Flexible Forward that can be drawn down and settled early whenever you need to.',
      drawdownNoFlexibilityInfo: 'Choose No Flexibility if you want a Fixed Forward - This cannot be drawn down on.',
      drawdownWindowInfo:
        'Choose to convert after a specific conversion date if you need a Window Forward that opens after the chosen date. You cannot drawdown before this Window date.',
      forward:
        'A Forward Trade is an FX transaction which contractually obliges you to convert a currency at a quoted rate but with a settlement date  ( also referred to as a delivery date) that is 3 or more business days in the future.',
      flexibleForward:
        "A flexible forward is designed for customers who would like to lock in the FX rate today for foregin currency conversions or payments that need to to do in the future, but they need to be able to drawdown that rate at any time. The Flexible forward is just like a regular forward but the client's Booked (contracted) rate is always the conversion rate regardless of how many times the client adjusts the settlement date. i.e there is no additional price adjustments like in a regular forward trade. The client can also settle his forward conversions in tranches (refered to as drawdowns). Once the flecxible forward is booked, the client has full flexibilty on when he wants to convert and how much he wants to convert (subject to the full drawdown being achieved) and how many times he wants to do this. His Conversion rate will always be known as it is confirmed at the time of original booking or trading, i.e the staty date. This gives you the oppprtunity to lock in a budget FX rate for the year ahead for your business.",
      windowForward:
        "Window forwards are a hybrid between flexible forwards and regular forwards. The Forward contract locks in a future conversion rate but doesnt lock in the rate if you wish to make early drawdowns or early settlements. Flexible forwards allow you to make early drawdowns or settlements by locking in the rate regardless of wheter you decide to settle or drawdown early or at the original contracted settlement date. Window Forwards are in essence fully flexible forwards but the first drawdown date is restricted to a date In the future, at which the 'Window' becomes open and the window forward becomes a flexible forward. If you are certain that you have no FX conversion needs until a certain date in the future, then you can select a window forward.",
      youAreBuying:
        'This is the currency and the amount that you will receive from you FX conversion. The buy currency is also the currency of your payments.',
      youAreSelling: 'The sell currency is the one you provide and that you want to have converted or exchanged.',
      valueDate:
        'The value date is the date when the trade is expected to be settled, when the sell currency is received and when the buy currency is paid out. It is also reffered to as the settlement date. Once a trade is confirmed, you will have an obligation to have paid the contracted settlement amount (the sell currency amount) on or before this value or settlement date.',
      deposit:
        'Your deposited currency(s) are added to your wallet when you make a bank trasnfer into the Personal IBAN associated with your account. If the currency wallet is available, you can instruct your bank to make the payment.',
      settlementDate:
        'The settlement date (value date) is when your sell currency must be received by, and the date that your buy currency is settled or paid to your beneficiaries.',
      startDate:
        'The trade start date records and initiates the transaction. It is also known as the trade execution date.',
      type: 'The trade type refers to the specific FX instrument that was traded or booked. It is usually FX Spot, Forward, Flexible Forward or Window Forward',
      tradeID: 'This is a unique transaction reference applied to the trade.',
      marginCreditFacility: `If a Margin Collateral Facility (MCF) has been granted by ALT 21, it can used as collateral for your initial margin and variable margin maintenance requirements of your portfolio of FX hedges and trades. Together with your 'Posted Collateral', it forms the 'Effective Collateral' that can be used against opening and holding trades.`,
      postedCollateral: `'Posted Collateral' is what you have allocated from your wallet into your margin collateral account. You can post collateral in USD, GBP, or EUR. You need to post collateral to cover the initial and variable margin maintenance requirements of your trades.`,
      marginCollateralAvailable: `Margin Collateral Available' is how much collateral you have left to further open new trades. It is what's left of your 'Effective Collateral' after deducting the 'Total Margin Requirement' already in-use from existing trades. You can always post more collateral from your wallet if there is insufficient 'Margin Collateral Available'`,
      email:
        "This is your email address and account username. [if private add 'Please avoid using a business email address that is subject to change.' - if business add 'Please avoid using a personal email address or shared (group) email address.']",
      countryOfResidency:
        'Your country of residence is the country in which you are currently living in, at the time you submit your application for this account.',
      countryOfIncorporation:
        'The country of incorporation identifies the country in which the company is incorporated or legally registered.',
      ultimateBeneficialOwner:
        'A UBO or Ultimate Beneficial Owner is the person or entity that is the ultimate beneficiary when an institution initiates a transaction. A UBO of a legal entity is a person who: Holds an interest of minimum 25% capital of the legal entity. Has minimum 25% voting rights at the general meeting of shareholders.',
      instrument:
        'This is the FX instrument of the trade contract, and can be FX Spot, Forwards, Flexible Forwards or Window Forward',
      stateTrade:
        'This is the current status of the trade. All trades move threw a cycle of statuses - from trade creation through to completion once fully settled.',
      endDate:
        'The value date is the date when the trade is required to be settled. Once the trade is confirmed, you will have an obligation to have paid the contracted settlement amount on or before this date.',
      contractRate:
        'This is the agreed exchange rate of the contract for the purpose of buying and selling currency. It is your traded, executed or booked FX rate.',
      contractStartDate:
        'The trade start date records and initiates the transaction. It is also known as the trade execution date.',
      drawdownEligible: 'Indicates whether it is currently possible to drawdown or early settle on forward contracts',
      windowStartDate:
        'The earliest date on which a partial or full drawdown of the position is available. In the case of a flexible forward, the Window Start date is considered to be the trade date and therefore it is immedatelty avaiable for Drawdown.',
      sendFunds:
        'Once the FX trade settles, you wish to send some or all of the currency bought to a chosen beneficiary.',
      keepFunds:
        'Once the FX trade settles, you wish to hold the currency bought in a wallet on account so that it can be sent to a beneficiary or withdrawn at a later date.',
      beneficiary:
        'A beneficiary is an individual or business who is eligible to receive funds from your wallets/account.',
      countryBeneficiary:
        'The country in which the beneficiary bank account is registered. Please note this may differ from the beneficiary country of residence or incorporation (if an entity).',
      initialMargin:
        'Initial margin is the amount that we hold as an initial risk buffer on every new trade. Your Margin Collateral Available deterimines if you have enough Effective Collateral to open a trade. You will need to post collateral if you do not have the required Margin Collateral Available',
      accountNumber: 'The account number of the beneficiary bank account.',
      sortCode: 'The sort code of the beneficiary bank account. Format, NN-NN-NN',
      SWIFT: 'The SWIFT code of the beneficiary bank account. This is also known as the BIC.',
      IBAN: 'The IBAN of the beneficiary bank account.',
      fullDrawdown:
        'A full drawdown is the early settlement of the Full Notional (the full amount) of a contracted or booked trade',
      partialDrawdown:
        'A partial Drawdown is a tranche drawdown of the Full Notional of a contracted or booked trade. You can make as many partial drawdowns as you wish on a particular contract until the full Notional has been drawndown.',
      drawdownAmount: `The Drawdown Amount is the portion of your existing trade that you wish to settle early.
        You specify this amount in the Fixed Currency side .i.e the currency side that you specified when you opened the original forward trade.
        The Drawdown FX Rate is the new FX rate that your Sell Currency will be converted at.
        In the case of a Flexible Forward, your Drawdown FX Rate will be the same as your original booked rate`,
      linkBankAccount:
        'Linking a bank account allows you to withdraw funds from this account. For security purposes, all withdrawals are subject to review and approval.',
      exchangeWallets:
        'To move an amount of funds from one currency wallet into a different currency wallet, a currency exchange or conversion needs to occur.',
      addMoneyWallets: 'Outlines how to add money (deposit funds) to your account wallets.',
      balance:
        'The amount, also referred to as the balance or the funds, that are held in the wallet. Aggregate balances are shown in equivalent terms in your accounting Currency',
      approxCCY:
        'This shows an approximate value of a currency wallet in your default account currency (this can be configured in account settings). The value is based on simple interbank rate conversion and is for information only. Please refresh the page to update the conversion.',
      regularFunding: 'This is a regular local payment and intermediary bank charges will not apply.',
      priorityFunding:
        'Priority payments are sent via the SWIFT network. When a payment is sent via SWIFT, intermediary bank charges may apply.',
      emailNotification:
        'An automated email that is sent when the notification event is triggered, sent to all verified email addresses of individuals (and those within groups).',
      inAppNotification:
        'An automated alert that is sent when the notification event is triggered, displays within the notification section of your account online when you are logged-in. This display only to those opted-in.',
      SMSNotification:
        'An automated SMS that is sent when the notification event is triggered, sent to the mobile telephone number of individuals (and those within groups).',
      alertRate:
        'The desired rate which when reached will trigger the notification. Please be aware that the notifcation will be sent when the desired rate inclusive of our trade margin is reached. Rate alerts are for information only and we are not obligated to honour a notification rate in the form of a quote.',
      requestDealerCall:
        'This will send a notification to our dealing desk who will contact you right away. Please note, dealer call backs are only available within office hours as stated on our website.',
      users:
        'This is an invited individual who has account access. They may also be part of one or more groups. Their permissions can be modified individually or at a group level.',
      groups:
        'This is a group of 1 or more individuals. If an individuals permissions or notifications conflict with that of a group they are in, the group settings will override.',
      marginCreditFaciity:
        "If a Margin Collateral Facilty( MCF) has been granted by ALT 21, it can used against your initial Margin and Variation Margin Maintenance requirements towards your Portfolio of FX Hedges and trades. Together with your 'Posted collateral', it forms the 'Effetive Collateral' that can be used against opening and holding trades.",
      postedCollateralAccount:
        'Posted Collateral account is your collateral wallet. It is funds that you have placed from your currency wallet as margin for initiating and holding trades. The Funds are still yours but they are locked until your positions are closed and settled or when the requirement for collateral is below a certain amount.',
      variationMarginMaintenance:
        'This is your variable margin requirements due to FX market movements. FX market movement cause the valuation of your trades to fluctuate, if the valuation is negative , there is a Variable margin maintenance requirement equal to the amount of the negative valuation. You must ensure that you have posted sufficient collateral to avoid margin calls and/or position closure.',
      effectiveCollateral:
        'Effective Collateral is the total collateral available to you to use against the initial margin and variable margin maintenance requirements.   It is the total of your posted collateral and your margin credit facility, and shown in GBP equivalent.',
      effectiveCollateralUtilisation:
        'Effective Collateral Utilisation (ECU%) is the % of your Effective Collateral that is being used up by your Variable Margin Maintenance requirements. The level of your ECU % triggers your alerts and notifications and is the level you need to monitor and maintain in your Collateral Management Screen. It is calculated as : variable Margin Maintenance / Effective Collateral. A 100% ECU means that your total margin requirements are equal to all the collateral you have posted plus any Margin Credit Facility that has been granted. We would normally send a Margin Call notice before this level is reached.',
      totalMarginRequirement: `Your open trades will each have an Initial margin and Variable Margin maintenance requirement. Together , this is your Total Margin requirement that is in-use against your Collateral . Your Collateral (margin) account must always have sufficient collateral to cover these requirements to avoid Margin calls and/or your positions being closed out.`,
      marginCreditContribution: `This is the total amount of Credit currently used to fund Initial Margin requirements.`,
      postedCollateralContribution: `This is the total amount of Posted Collateral currently used to fund Initial Margin requirements.`,
      marginCreditAvailable: `This is the total amount of Credit available to trade`,
      postedCollateralAvailable: `This is the total amount of Collateral available to trade`,
      initialMarginRequirement: `All trades have an Initial Margin requirement . You must have sufficient collateral to cover the initial margin amount required to open a trade initial margin rates are dependent on the currency pair and product that you are trading.`,
      fxPositionLimits:
        'FX Position Limits are the maximum notional positon you can hold in each category or the total category whichever is reached sooner',
      markToMarket:
        'FX instruments are financial contracts that have a continuous Mark to Market Value. This is based on the difference between the contracted rate and the current interbank mid market rate. It is not the amount you can expect to pay or receive if you closed or cancelled your trade becasue it ignores various spreads and costs, but it is reasonable close and fair. MTM is used to value your portfolio in real time to asses if you have sufficient posted collateral. It is also used for your reports .',
      maximumNotionalValue:
        'This is the total maximum notional value of open trades allowed at one time (in GBP equivalency).  This excludes settled trades.',
      netEquity:
        'This is the clients overall equity position and is made up of Posted collateral, plus their MTM, less Initial Margin Requirements.',
      capitalAtRiskBuffer:
        "Capital At-Risk Buffer is the amount by which the clients positions are in the money from ALT 21's point of view.",
      mcfUsage:
        'MCF Usage % is the portion of the clients Margin Credit facility that is in use agasint his open trades. It is the portion of his allocated facility that is being used to cover IM and VM requirements . the rest is usually made up of Posted Collateral.',
      capitalAtRisk:
        'Capital At-Risk is the amount by which ALT 21 is exposed if a client were to be in default.  It is the difference between the collateral the client has posted and the MTM of his trades. It does not count MCF as collateral. ',
      IMCreditCharges: 'IM Credit Charges are charge that we have applied to the partner of this client.',
      acruedDailyMcfFundingCost:
        'Accrued MCF Funding cost is the assumed accrued daily cost of credit based on the annual Margin funding rate and the amount of MCF that is in use by the client. ',
      swiftFeeType:
        'ACO - All Charges Ours. <br> Your Swift payment will arrive at the recipient without correspondent bank fees being deducted. In most cases, the client will receive the amount intended. <br><br> SHA - Shared Charges. <br> Although more cost effective for the sender, there may be some correspondent bank fees applied to the amount being sent. Your beneficiary may not receive the exact amount you send. ',
      commissionDisplayType:
        'The original display mode shows you the origination commissions, i.e any incremental new commission that is earned or lost on the trade at the time of opening or drawdown. The Apportioned view shows you commissions relative to the notional that is either remaining on the opening trade, or if it’s not an opening trade, you are shown the commission that is the relative portion of the (remaining) drawdown. If the trade has been rolled forward, the Apportioned view will apportion your commissions to the (remaining) rolled forward trade.',
      commissionsEquiv:
        'This is your Total commission made up of mark up, Swap PNL commissions, Drawdown commissions, any Roll Forward PNL plus any commission adjustments.',
      imccEquiv: 'This is the shared portion of the Gross Initial Margin Credit Charge on the clients’ trade.',
      netCommission: 'Your commission less IMCC',
      apportionedCommDate:
        'The Apportioned Commission Date refers to the commissions settlement date of the remaining main opening trade, or the date for the commissions associated with the drawdown, or the date associated with any trade that is rolled forward. Commissions date typically follow the latter of the original settlement date and any trade that has had it’s date rolled forward. ',
      swapPnlCommission: 'The PNL derived from swap points when a client trades a flexible forward.',
      dateRollCommission: 'Date Roll derived from the swap points when a client drawdowns or rolls forward a trade.',
      markUpBp: 'The mark up of the original opening trade',
      marginCallSettings:
        'This is the ECU level at which the margin Call is triggered . A margin called Client Must recapitlise his account so that the new ECU is below the Recapitalisation Threshold.',
      autoCutOutSettings:
        'Please note - we never want to get here and to avoid this cutout happening from a bad data print, the level is best kept artificially high.',
      RTSettings:
        'This is how much the client has to take his ECU to once his margin call is triggered (this also resets the notification messages reminding client of his margin call).',
      UCTSettings:
        'A Client can withdraw collateral if it falls under the Unutilised Collateral Threshold that you set here. Other conditions also need to be met. i.e, The client must have margin collateral available in his account and he must have posted collateral.',
      RRTSettings:
        'It is recommended that client post or hold collateral below this ECU threshold at all times to reduce the risk of premature margin calls.',
      finalMarginCallSettings:
        'Client will be sent one final warning to post collateral to satisfy the Variation Margin requirement',
      firstMarginCallSettings:
        'Client will be sent the first warning to post collateral to satisfy the Variation Margin requirement',
      disableCollateralWithdrawalSettings:
        'Client will not be permitted to withdraw collateral until Credit Utilisation is below this threshold',
      costsTransparency:
        'We believe in providing clear pricing to help you make informed choices. We want to be transparent with clients so they know they are getting a fair deal and can feel confident when using our service.',
      xHedgeProUser:
        '<b>Regular User:</b> The client can create <b>FX Spots</b> and <b>FX Forwards</b> trades only. <br><br> <b>Pro User:</b> The client can create <b>FX Spots</b>, <b>FX Forwards</b>, and <b>Options</b> trades.',
    },
  },
};
