<script setup lang="ts">
import { WalletFundingDetails } from 'ah-api-gateways';
import { useAhWalletsState } from '..';
import { computed, onMounted } from 'vue';

const props = defineProps<{
  fundingDetails: WalletFundingDetails;
}>();

const walletState = useAhWalletsState();

onMounted(() => walletState.store.useSettingsStore().loadCountries());

const countryName = computed(() => {
  const country = walletState.store
    .useSettingsStore()
    .allCountries.find((c) => c.cc === props.fundingDetails.bankCountry);

  return country?.name ?? '';
});
</script>

<template>
  <div>
    <DataRow label="Account Holder" class="account-holder">
      {{ fundingDetails.accountHolderName }}
    </DataRow>
    <DataRow label="Bank" class="account-bank">
      {{ fundingDetails.bankName }}
    </DataRow>
    <DataRow label="Bank Address" class="account-bank-address">
      {{ fundingDetails.bankAddress }}
      <template v-if="countryName">
        <br />
        {{ countryName }}
      </template>
    </DataRow>
    <DataRow label="IBAN" v-if="fundingDetails.iban" class="account-iban">
      {{ fundingDetails.iban }}
    </DataRow>
    <DataRow label="Account number" v-if="fundingDetails.accountNumber" class="account-number">
      {{ fundingDetails.accountNumber }}
    </DataRow>
    <DataRow label="Routing Code" v-if="fundingDetails.routingCode" class="account-routing-code">
      {{ fundingDetails.routingCode }}
    </DataRow>
  </div>
</template>
