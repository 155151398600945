<script setup lang="ts">
import UserLogin from '@/app/components/session/UserLogin.vue';
import { BrandingImageType } from 'ah-api-gateways';
import { useAuthStore } from '@/app/store/authStore';
import { useTheme } from 'ah-theme';
import { useRouter } from 'vue-router/composables';
import { computed, ref } from 'vue';

const props = defineProps({
  redirectTo: { type: String, default: '' },
  redirectToQuery: { type: String, default: '' },
});

const authStore = useAuthStore();

const theme = useTheme();

const router = useRouter();

const userLogin = ref<UserLogin>();

const currentStage = ref<String>('');

const splashLogoLink = computed(() => {
  let out = theme.val?.logos.find((l) => l.type === BrandingImageType.SPLASH_IMAGE)?.link;
  return out;
});

function resendCode() {
  userLogin.value?.refreshOtpCode();
}

function onLoginSuccess() {
  if (authStore.isUnverifiedSelfRegister) {
    router.push('/register');
    return;
  }
  let path = authStore.isClientUser ? '/dashboard' : '/admin';
  let query = {};
  try {
    query = JSON.parse(props.redirectToQuery);
  } catch (e) {}
  if (props.redirectTo) {
    path = props.redirectTo;
  }
  router.push({ path, query });
}

/**
 * Clearing session for the use case where a logged in user goes back
 * to the login screen while with a registration session
 */
if (authStore.hasRegistrationSession) {
  authStore.logout();
}
</script>

<template>
  <SimpleCenteredPage narrow id="login-view">
    <img class="company-logo-large" :src="splashLogoLink" alt="" v-if="splashLogoLink && currentStage === 'login'" />
    <h1 class="mb-5" v-else-if="currentStage === 'otp'">{{ $t('login.twoStepAuth') }}</h1>
    <UserLogin id="user-login" ref="userLogin" @login-success="onLoginSuccess" @stage-change="currentStage = $event" />
    <template #footer>
      <div v-if="currentStage === 'login'">
        <RouterLink to="/resetPassword"> {{ $t('login.forgotPassword') }} </RouterLink>
        <span class="d-sm-none d-md-inline mx-2">|</span>
        <span class="d-sm-block d-md-inline mx-1"
          >{{ $t('login.dontHaveAccount') }} <RouterLink to="/register"> {{ $t('login.register') }} </RouterLink></span
        >
      </div>
      <div v-else-if="currentStage === 'otp'">
        {{ $t('login.didntReceive') }}
        <a href="#" @click.prevent="resendCode">{{ $t('login.resendCode') }}</a>
      </div>
    </template>
  </SimpleCenteredPage>
</template>

<style lang="scss" scoped>
.company-logo-large {
  line-height: 200px;
  width: 100%;
  text-align: center;
  margin-bottom: $padded-space * 5;
}
</style>
