<template>
  <div class="popover-container dropdown-menu-block">
    <div class="popover-section">
      <ul>
        <RouterLink tag="li" :to="`${settingsBase}/account`">Account Settings</RouterLink>
        <RouterLink tag="li" :to="`${settingsBase}/personal-settings`">Personal Settings</RouterLink>
        <RouterLink tag="li" :to="`${settingsBase}/dev-settings`" v-if="showDevTools">[Dev Settings]</RouterLink>
        <li class="popover-section clickable" @click="$emit('logout')">Log out</li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { useAuthStore } from '@/app/store/authStore';
import config from '@/config';

@Component
export default class UserMenu extends Vue {
  get authStore() {
    return useAuthStore();
  }

  get showDevTools() {
    return config.showDevTools;
  }

  get user() {
    return this.authStore.loggedInIdentity;
  }

  get settingsBase() {
    if (this.authStore.isAgent) {
      return '/admin/settings';
    }
    return '/settings';
  }
}
</script>

<style lang="scss" scoped>
.popover-container {
  float: left;
  position: relative;
  top: 5px;
  border-radius: $user-menu-radius;
  padding: 1px;
}
</style>
